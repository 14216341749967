import { createSlice } from "@reduxjs/toolkit";
import { getOtherHistory } from "./historyActions";

const initialState = {
  otherHistory: [],
  otherHistoryError: null,
  otherHistoryIsLoading: false,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: {
    [getOtherHistory.fulfilled]: (state, {payload}) => {
      state.otherHistoryIsLoading = false;
      state.otherHistory = payload;
    },
    [getOtherHistory.rejected]: (state, payload) => {
      state.otherHistoryIsLoading = false;
      state.otherHistoryError = payload;
    },
    [getOtherHistory.pending]: (state) => {
      state.otherHistoryIsLoading = true;
    },
  },
});

export default historySlice.reducer;
