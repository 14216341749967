import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const getProfits = createAsyncThunk(
  "get-profits",
  async ({ owner_id }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${endPoint}/profits/?owner=${owner_id}`,
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
