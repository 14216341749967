import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const DashboardWraper = styled(MainWrapper)`
`;

export const UserH2 = styled.h2`
  color: #343a40 !important;
  padding-bottom: 0.5rem !important;
  line-height: 1.4;
  font-size: 1.35rem;
  margin-block: 0 0.5rem;
  font-weight: 500;
`;

export const UserH5 = styled.h2`
  color: rgba(169, 175, 187, 0.82) !important;
  margin-bottom: 1.5rem !important;
  margin-top: 0;
  line-height: 1.4;
  font-size: 0.9125rem;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
  }
`;

export const Card = styled.div`
  position: relative;
  word-wrap: break-word;

  display: flex;
  align-items: center;
  padding: 1rem !important;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({theme}) => theme.card.background};
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  border: 0;
`;

export const CardSpan = styled.span`
  color: ${({ iconColor }) => iconColor};
  background: ${({ iconBGColor }) => iconBGColor};
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  margin-right: 1rem !important;
`;

export const CardH5 = styled.h5`
  color: #343a40 !important;
  margin-bottom: 0.25rem !important;
  line-height: 1.4;
  font-size: 0.9125rem;
  margin-top: 0;
`;

export const CardSmall = styled.small`
  color: #6c757d !important;
  font-size: 13px;
  font-weight: 400;
`;
