import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const SupportWrapper = styled(MainWrapper)``;

export const SupportCard = styled.div`
  position: relative;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border: 0;
`;

export const SupportTextInput = styled.textarea`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;

  outline: none;
`;
