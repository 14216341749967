import React, { useState } from "react";
import {
  Caret,
  NavDDUlLi,
  NavLi,
  NavLiDD,
  NavLiLink,
  NavUl,
  ScrollWrapper,
  SideNavBarStyle,
  User,
} from "./SideNavBar.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faBriefcase,
  faCoins,
  faCubes,
  faDownload,
  faHome,
  faRecycle,
  faSignal,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { faLifeRing } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SideNavBar = (props) => {
  const { ShowSideNavBar, setShowSideNavBar } = props;
  const [collapseInfo, setCollapseInfo] = useState(true);
  const [showStakeMenu, setShowStakeMenu] = useState(false);

  const {user} = useSelector(state => state.user)

  const navLinkData = [
    {
      name: "Dashboard",
      link: "dashboard",
      icon: <FontAwesomeIcon icon={faHome} />,
    },
    {
      name: "Fund your Account",
      link: "/dashboard/deposits",
      icon: <FontAwesomeIcon icon={faDownload} />,
    },
    {
      name: "Withdraw Funds",
      link: "/dashboard/withdrawals",
      icon: <FontAwesomeIcon icon={faArrowAltCircleUp} />,
    },
    {
      name: "Profit Record",
      link: "/dashboard/tradinghistory",
      icon: <FontAwesomeIcon icon={faSignal} />,
    },
    {
      name: "Transactions History",
      link: "/dashboard/accounthistory",
      icon: <FontAwesomeIcon icon={faBriefcase} />,
    },
    {
      name: "Crypto Exchange",
      link: "/dashboard/asset-balance",
      icon: <FontAwesomeIcon icon={faCoins} />,
    },
    {
      name: "Trading",
      link: "/dashboard/trading",
      icon: <FontAwesomeIcon icon={faTh} />,
    },
    {
      name: "Stake",
      icon: <FontAwesomeIcon icon={faCubes} />,
    },
    {
      name: "Refer Users",
      link: "/dashboard/referuser",
      icon: <FontAwesomeIcon icon={faRecycle} />,
    },
    {
      name: "Help/Support",
      link: "/dashboard/support",
      icon: <FontAwesomeIcon icon={faLifeRing} />,
    },
  ];
  return (
    <SideNavBarStyle ShowSideNavBar={ShowSideNavBar}>
      <ScrollWrapper>
        <User>
          <span
            style={{
              color: "#777",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "400",
              height: "35px",
            }}
            onClick={() => setCollapseInfo(!collapseInfo)}
          >
            {user?.full_name} <Caret isOpen={!collapseInfo}></Caret>
          </span>
          <span
            style={{
              color: "#777",
              display: collapseInfo ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "400",
              height: "35px",
            }}
          >
            <Link
              to="/dashboard/account-settings"
              style={{
                color: "#777",
                textDecoration: "none",
              }}
            >
              Account Settings
            </Link>
          </span>
        </User>
        <NavUl>
          {navLinkData.map((data, index) =>
            data.name === "Stake" ? (
              <NavLi key={index}>
                <NavLiDD
                  onClick={() => {
                    setShowStakeMenu(!showStakeMenu);
                  }}
                >
                  <FontAwesomeIcon icon={faCubes} />
                  <p>Stake</p>
                  <Caret isOpen={showStakeMenu}></Caret>
                </NavLiDD>
                <div
                  style={{
                    display: showStakeMenu ? "block" : "none",
                    userSelect: "none",
                  }}
                >
                  <ul>
                    <NavDDUlLi>
                      <NavLiLink
                        to="/dashboard/buy-plan"
                        onClick={() => {
                          setShowSideNavBar(false);
                        }}
                      >
                        <span className="sub-item">Pick a staking Plan</span>
                      </NavLiLink>
                    </NavDDUlLi>
                    <NavDDUlLi>
                      <NavLiLink
                        to="/dashboard/myplans"
                        onClick={() => {
                          setShowSideNavBar(false);
                        }}
                      >
                        <span className="sub-item">My stakes</span>
                      </NavLiLink>
                    </NavDDUlLi>
                  </ul>
                </div>
              </NavLi>
            ) : (
              <NavLi key={index}>
                <NavLiLink
                  to={data.link}
                  onClick={() => {
                    setShowSideNavBar(false);
                  }}
                >
                  {data.icon}
                  <p>{data.name}</p>
                </NavLiLink>
              </NavLi>
            )
          )}
        </NavUl>
      </ScrollWrapper>
    </SideNavBarStyle>
  );
};

export default SideNavBar;
