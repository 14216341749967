import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import Loading from "../../../components/Loading/Loading";
import {
  createWithdrawalSettings,
  getWithdrawalSettings,
  updateWithdrawalSettings,
} from "../../../features/withdrawal/withdrawalActions";

const WithdrawalSettings = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {
    withdrawalSettings,
    createWithdrawalSettingsIsLoading,
    updateWithdrawalSettingsIsLoading,
  } = useSelector((state) => state.withdrawal);

  useEffect(() => {
    const owner_id = user.id;
    dispatch(getWithdrawalSettings({ owner_id }));
  }, [dispatch, user.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bank_name: withdrawalSettings?.bank_name,
      account_name: withdrawalSettings?.account_name,
      account_number: withdrawalSettings?.account_number,
      swift_code: withdrawalSettings?.swift_code,
      bitcoin: withdrawalSettings?.bitcoin,
      ethereum: withdrawalSettings?.ethereum,
      litecoin: withdrawalSettings?.litecoin,
    },
    validationSchema: Yup.object({
      bank_name: Yup.string(),
      account_name: Yup.string(),
      account_number: Yup.number(),
      swift_code: Yup.string(),
      bitcoin: Yup.string(),
      ethereum: Yup.string(),
      litecoin: Yup.string(),
    }),
    onSubmit: (values) => {
      Object.keys(withdrawalSettings).length === 0
        ? dispatch(createWithdrawalSettings({ owner: user.id, ...values }))
        : dispatch(updateWithdrawalSettings({ owner: user.id, ...values }));
    },
  });

  return (
    <PSFormWrapper>
      <PSForm onSubmit={formik.handleSubmit}>
        <h3>Bank Account</h3>
        <PSFormGrid>
          <PSFormGroup>
            <label htmlFor="bank_name">Bank Name</label>
            <PSInput
              type="text"
              id="bank_name"
              name="bank_name"
              onChange={formik.handleChange}
              value={formik.values.bank_name}
              placeholder="Enter bank name"
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="account_name">Account Name</label>
            <PSInput
              type="text"
              id="account_name"
              name="account_name"
              onChange={formik.handleChange}
              value={formik.values.account_name}
              placeholder="Enter account name"
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="account_number">Account Number</label>
            <PSInput
              type="number"
              id="account_number"
              name="account_number"
              onChange={formik.handleChange}
              value={formik.values.account_number}
              placeholder="Enter account number"
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="swift_code">Swift Code</label>
            <PSInput
              type="text"
              id="swift_code"
              name="swift_code"
              onChange={formik.handleChange}
              value={formik.values.swift_code}
              placeholder="Enter swift code"
            />
          </PSFormGroup>
        </PSFormGrid>
        <h3>Cryptocurrency</h3>
        <PSFormGrid>
          <PSFormGroup>
            <label htmlFor="bitcoin">Bitcoin</label>
            <PSInput
              type="text"
              id="bitcoin"
              name="bitcoin"
              onChange={formik.handleChange}
              value={formik.values.bitcoin}
              placeholder="Enter Bitcoin Address"
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="ethereum">Ethereum</label>
            <PSInput
              type="text"
              id="ethereum"
              name="ethereum"
              onChange={formik.handleChange}
              value={formik.values.ethereum}
              placeholder="Enter Ethereum Address"
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="litecoin">Litecoin</label>
            <PSInput
              type="text"
              id="litecoin"
              name="litecoin"
              onChange={formik.handleChange}
              value={formik.values.litecoin}
              placeholder="Enter Litecoin Address"
            />
          </PSFormGroup>
        </PSFormGrid>
        <PSSubmitBtn type="submit">
          {updateWithdrawalSettingsIsLoading ||
          createWithdrawalSettingsIsLoading ? (
            <Loading />
          ) : Object.keys(withdrawalSettings).length === 0 ? (
            "Save"
          ) : (
            "Update"
          )}
        </PSSubmitBtn>
      </PSForm>
    </PSFormWrapper>
  );
};

const PSFormWrapper = styled.div``;

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(2, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSInput = styled.input`
  height: 40px;
  padding-inline: 5px;
  outline: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 1px solid #ced4da;

`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default WithdrawalSettings;
