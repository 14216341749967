// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  getUser,
  updateUser,
  getNotificationSettings,
  updateNotificationSettings,
  getUserReferrals,
} from "./UserActions";

const initialState = {
  userIsLoading: false,
  user: {},
  error: null,
  notificationSettings: {},
  referredUsers: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [getUser.pending]: (state) => {
      state.userIsLoading = true;
      state.error = null;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.userIsLoading = false;
      state.user = payload.data;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.userIsLoading = false;
      state.error = payload;
    },
    [updateUser.pending]: (state) => {
      state.userIsLoading = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.userIsLoading = false;
      state.user = payload.data;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.userIsLoading = false;
      state.error = payload;
    },
    [getUserReferrals.pending]: (state) => {
      state.userIsLoading = true;
      state.error = null;
    },
    [getUserReferrals.fulfilled]: (state, { payload }) => {
      state.userIsLoading = false;
      state.referredUsers = payload.data;
    },
    [getUserReferrals.rejected]: (state, { payload }) => {
      state.userIsLoading = false;
      state.error = payload;
    },
    [getNotificationSettings.pending]: (state) => {
      state.userIsLoading = true;
      state.error = null;
    },
    [getNotificationSettings.fulfilled]: (state, payload) => {
      state.userIsLoading = false;
      state.notificationSettings = payload.data;
    },
    [getNotificationSettings.rejected]: (state, payload) => {
      state.userIsLoading = false;
      state.error = payload;
    },
    [updateNotificationSettings.pending]: (state) => {
      state.userIsLoading = true;
      state.error = null;
    },
    [updateNotificationSettings.fulfilled]: (state, { payload }) => {
      state.userIsLoading = false;
      state.notificationSettings = payload.data;
    },
    [updateNotificationSettings.rejected]: (state, { payload }) => {
      state.userIsLoading = false;
      state.error = payload;
    },
  },
});

export default userSlice.reducer;
