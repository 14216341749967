import styled from "styled-components";

export const TableCard = styled.div`
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border: 0;
  padding: 1rem !important;
`;

export const TableResponsive = styled.div`
  width: 100%;
  /* overflow-x: auto; */
`;

export const UserTableWrapper = styled.div`
  text-align: center !important;
`;

export const TableRow = styled.div`
  margin: 0;

  display: flex;
  flex-wrap: wrap;
`;

export const TableElement = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;

  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
`;

export const TableHeadElement = styled.thead`
  & > th {
    border-bottom: 2px solid #dee2e6;
  }
`;

export const TableRowElement = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;

  & > td,
  & > th {
    font-size: 14px;
    border-top-width: 0;
    border-bottom: 1px solid;
    border-color: #ebedf2 !important;
    padding: 0 25px !important;
    height: 60px;
    vertical-align: middle !important;
  }
`;

export const TableSelect = styled.select`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;
`;

export const TableInput = styled.input`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;
`;
