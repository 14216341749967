import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthStyle } from "./Auth.styled";

const Auth = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <AuthStyle>
      <Outlet />
    </AuthStyle>
  );
};

export default Auth;
