import React from "react";
import { convertInlineStyles } from "../../utils/utils";
import { TabItem } from "./Tab.styled";

const Tab = ({ tabList, activeTabItem, setActiveTabItem }) => {
  return (
    <div
      style={convertInlineStyles(
        "border-bottom: 1px solid #dee2e6; display: flex; flex-wrap: wrap; padding: 0px; margin-bottom: 0; list-style: none; justify-content: space-between; margin-bottom: 20px;"
      )}
    >
      {tabList.map((data, index) => (
        <TabItem
          key={index}
          className={data === activeTabItem ? "active" : ""}
          onClick={() => setActiveTabItem(data)}
        >
          {data}
        </TabItem>
      ))}
    </div>
  );
};

export default Tab;
