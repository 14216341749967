import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { convertInlineStyles } from "../../utils/utils";
import { logoutUser } from "../../features/auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { DropDownMenuWrapper } from "./DropDownMenu.styled";

const DropDownMenu = ({ showDropDownMenu, setShowDropDownMenu, setShowTopNavBarMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  return (
    <DropDownMenuWrapper showDropDownMenu={showDropDownMenu}>
      <li>
        <div
          style={{
            display: "flex",
            padding: "0.25rem 1rem",
          }}
        >
          <div className="u-text">
            <h4>{user?.full_name}</h4>
            <p className="text-muted">{user?.email}</p>
            <Link
              to="/dashboard/account-settings"
              onClick={() => {
                setShowDropDownMenu(false);
                setShowTopNavBarMenu(false);
              }}
              style={convertInlineStyles(`
                background-color: #6861ce;
                line-height: 1.5px;
                color: #fff;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                text-decoration: none;
                padding: 13px 9px;
                opacity: 1;
                border-radius: 4px;
                border-bottom-left-radius: 0;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                border: 1px solid transparent;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                -webkit-text-size-adjust: 100%;
                -webkit-tap-highlight-color: transparent;
              `)}
            >
              Account Settings
            </Link>
          </div>
        </div>
      </li>
      <div
        style={convertInlineStyles(`
          height: 0;
          margin: 0.5rem 0;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        `)}
      ></div>
      {[
        {
          name: "Deposit",
          link: "/dashboard/deposits",
        },
        {
          name: "Withdraw",
          link: "/dashboard/withdrawals",
        },
        {
          name: "Buy Plan",
          link: "/dashboard/buy-plan",
        },
      ].map((data, index) => (
        <li
          key={index}
          style={{ padding: "0.25rem 1rem", cursor: "pointer" }}
          onClick={() => {
            navigate(data.link);
            setShowDropDownMenu(false);
            setShowTopNavBarMenu(false);
          }}
        >
          {data.name}
        </li>
      ))}
      <div
        style={convertInlineStyles(`
          height: 0;
          margin: 0.5rem 0;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        `)}
      ></div>
      <li
        style={{ padding: "0.25rem 1rem", cursor: "pointer" }}
        onClick={() => {
          dispatch(logoutUser({}))
            .unwrap()
            .then(() => navigate("/login"));
        }}
      >
        Logout
      </li>
    </DropDownMenuWrapper>
  );
};

export default DropDownMenu;
