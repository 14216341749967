import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const WithdrawalWrapper = styled(MainWrapper)``;

export const WithdrawalCoinWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
`;

export const WithdrawalCoin = styled.div`
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  transition: 0.3s;
  padding: 1.5rem !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;

export const WithdrawalCoinRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block: 30px;
`;

export const WithdrawalCoinBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  width: 100%;
  margin-inline: auto;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;
