import React, { useEffect, useState } from "react";
import { AccountHistoryWarapper } from "./AccountHistory.styled";
import { convertInlineStyles } from "../../utils/utils";
import Table from "../../components/Table/Table";
import Tab from "../../components/Tab/Tab";
import { useDispatch, useSelector } from "react-redux";
import { getOtherHistory } from "../../features/history/historyActions";
import { getWithdrawals } from "../../features/withdrawal/withdrawalActions";
import { getDeposits } from "../../features/deposit/depositActions";

const AccountHistory = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { otherHistory } = useSelector((state) => state.history);
  const { withdrawals } = useSelector((state) => state.withdrawal);
  const { deposits } = useSelector((state) => state.deposit);
  const [activeTabItem, setActiveTabItem] = useState("Deposits");

  useEffect(() => {
    dispatch(getOtherHistory({ owner_id: user.id }));
    dispatch(getWithdrawals({ owner_id: user.id }));
    dispatch(getDeposits({ owner_id: user.id }));
  }, [dispatch, user.id]);

  return (
    <AccountHistoryWarapper>
      <div
        style={convertInlineStyles(
          "margin-bottom: 1.5rem; margin-top: 0.5rem;"
        )}
      >
        <h1
          style={convertInlineStyles(
            "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
          )}
        >
          Transactions on your account
        </h1>
        <div>
          <Tab
            tabList={["Deposits", "Withdrawals", "Others"]}
            activeTabItem={activeTabItem}
            setActiveTabItem={setActiveTabItem}
          />
          {activeTabItem === "Deposits" ? (
            <Table
              tableHead={["Amount", "Pay Mode", "Status", "Date created"]}
              data={deposits.map((deposit) => {
                return {
                  amount: `$${deposit?.amount}`,
                  pay_mode: deposit?.pay_mode,
                  status: deposit?.status,
                  date_created: new Date(
                    deposit?.date_created
                  ).toLocaleString(),
                };
              })}
            />
          ) : activeTabItem === "Withdrawals" ? (
            <Table
              tableHead={[
                "Amount Requested",
                "Amount Charges",
                "Receiving Mode",
                "Status",
                "Date created",
              ]}
              data={withdrawals.map((withdrawal) => {
                return {
                  amount_requested: `$${withdrawal?.amount_requested}`,
                  charges: withdrawal?.charges,
                  receive_mode: withdrawal?.receive_mode,
                  status: withdrawal?.status,
                  date_created: new Date(
                    withdrawal?.date_created
                  ).toLocaleString(),
                };
              })}
            />
          ) : activeTabItem === "Others" ? (
            <Table
              tableHead={["Amount", "Type", "Plan/Naration", "Date created"]}
              data={otherHistory.map((otherHistory) => {
                return {
                  amount: `$${otherHistory?.amount}`,
                  type: otherHistory?.type,
                  plan: otherHistory?.plan,
                  date: new Date(otherHistory?.date).toLocaleString(),
                };
              })}
            />
          ) : null}
        </div>
      </div>
    </AccountHistoryWarapper>
  );
};

export default AccountHistory;
