import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const AccountHistoryWarapper = styled(MainWrapper)`
  padding: 24px 15px;

  @media only screen and (min-width: 991px) {
    padding: 24px 32px;
  }
`;
