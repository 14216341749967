import { createSlice } from "@reduxjs/toolkit";
import { getDeposits, makeDeposit } from "./depositActions";

const initialState = {
  getDepositSettingsError: null,
  getDepositSettingsIsLoading: false,
  deposits: [],
  makeDepositError: null,
  makeDepositIsLoading: false,
  deposit: {},
};

const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {},
  extraReducers: {
    [getDeposits.fulfilled]: (state, { payload }) => {
      state.getDepositSettingsIsLoading = false;
      state.deposits = payload.data;
    },
    [getDeposits.rejected]: (state, payload) => {
      state.getDepositSettingsIsLoading = false;
      state.getDepositSettingsError = payload;
    },
    [getDeposits.pending]: (state) => {
      state.getDepositSettingsIsLoading = true;
    },
    [makeDeposit.fulfilled]: (state, { payload }) => {
      state.makeDepositIsLoading = false;
      state.deposit = payload.data;
    },
    [makeDeposit.rejected]: (state, payload) => {
      state.makeDepositIsLoading = false;
      state.makeDepositError = payload;
    },
    [makeDeposit.pending]: (state) => {
      state.makeDepositIsLoading = true;
    },
  },
});

export default depositSlice.reducer;
