import { createSlice } from "@reduxjs/toolkit";
import { getProfits } from "./ProfitlActions";

const initialState = {
  profits: [],
  profitsError: null,
  profitsIsLoading: false,
};

const profitSlice = createSlice({
  name: "profit",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfits.fulfilled]: (state, { payload }) => {
      state.profitsIsLoading = false;
      state.profits = payload.data;
    },
    [getProfits.rejected]: (state, payload) => {
      state.profitsIsLoading = false;
      state.profitsError = payload;
    },
    [getProfits.pending]: (state) => {
      state.profitsIsLoading = true;
    },
  },
});

export default profitSlice.reducer;
