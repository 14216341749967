import React from "react";

const ThemeTogglerButton = ({ handleClick, themeState }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        marginInline: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {themeState === "dark" ? (
        <svg
          width="80"
          height="30"
          viewBox="0 0 406 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="406" height="188" rx="94" fill="#34E545" />
          <circle cx="312" cy="94" r="71" fill="white" />
        </svg>
      ) : (
        <svg
          width="80"
          height="30"
          viewBox="0 0 406 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="406" height="188" rx="94" fill="grey" />
          <circle cx="94" cy="94" r="71" fill="white" />
        </svg>
      )}
      <div style={{ color: "#fff" }}>darkmode</div>
    </div>
  );
};

export default ThemeTogglerButton;
