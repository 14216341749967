import styled from "styled-components";

export const TabItem = styled.h4`
  flex: 1 1 auto;
  text-align: center;

  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  margin-bottom: -1px;

  display: block;
  padding: 16px 1rem;
  cursor: pointer;
  color: #a9afbbd1;

  &:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
  }

  &.active {
    color: #495057;
    background: #fff !important;
    border-color: #dee2e6 #dee2e6 #fff;
  }

`;
