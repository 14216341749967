import React from "react";
import { PricingTableItem, PricingTableWrapper } from "./PricingTable.styled";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const PricingTable = ({ pricingData }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: pricingData[0],
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      navigate("/dashboard/deposits", {
        state: {
          error:
            "Your account is insufficient to purchase this plan. Please make a deposit.",
        },
      });
    },
  });
  return (
    <PricingTableWrapper>
      {pricingData.map((data, index) => (
        <PricingTableItem>
          <h2 className="text-dark">
            {data.currency} {data.is_hot && "(HOT)🔥"}
          </h2>
          <div className="price-tag">
            <span className="symbol text-dark">$</span>
            <span className="amount text-dark">APR({data.price_tag}%)</span>
          </div>
          <div className="pricing-features">
            <div className="feature text-dark">
              Minimum Possible stake:
              <span className="text-dark">
                {data.min_possible_stake.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            {/* <div className="feature text-dark">
              Maximum Possible stake:
              <span className="text-dark">{data.max_possible_stake.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}</span>
            </div> */}
            <div className="feature text-dark">
              Minimum yield:
              <span className="text-dark">
                {data.min_yield.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="feature text-dark">
              Maximum yield:
              <span className="text-dark">
                {data.max_yield.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="feature text-dark">
              Gift Bonus:
              <span className="text-dark">
                {data.gift_bonus.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="feature text-dark">
              Duration:<span className="text-dark">{data.duration} days</span>
            </div>
          </div>
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              <h5 className="text-dark">
                Amount to stake: ($APR (0.022%) default)
              </h5>
              <PSInput
                type="number"
                name="iamount"
                placeholder="$APR (0.022%)"
                className="form-control text-dark bg-light"
              />{" "}
              <br />
              <PSSubmitBtn type="submit">Join plan</PSSubmitBtn>
            </form>
          </div>
        </PricingTableItem>
      ))}
    </PricingTableWrapper>
  );
};

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

const PSInput = styled.input`
  height: 40px;
  width: calc(100% - 20px);
  padding-inline: 10px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;
`;

export default PricingTable;
