import React, { useEffect } from "react";
import { convertInlineStyles } from "../../utils/utils";
import {
  ReferUserButton,
  ReferUserCard,
  ReferUserCardWrapper,
  ReferUserInput,
  ReferUserWrapper,
} from "./ReferUser.styled";
import Table from "../../components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUser } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import copyToClipboard from "../../utils/copyToClipboard";
import { getUserReferrals } from "../../features/user/UserActions";

const ReferUser = () => {
  const dispatch = useDispatch();
  const { user, referredUsers } = useSelector((state) => state.user);
  const referralLink = `${process.env.REACT_APP_BASE_URL}/register/?referral=${user?.referral_code}`;

  useEffect(() => {
    dispatch(getUserReferrals({ ref_code: user?.referral_code }));
  }, [dispatch, user?.referral_code]);

  return (
    <ReferUserWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Refer users to StakecornServices community
      </h1>

      <ReferUserCardWrapper>
        <ReferUserCard>
          <strong>You can refer users by sharing your referral link:</strong>
          <br />
          <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
            <ReferUserInput
              type="text"
              value={referralLink}
              id="myInput"
              readonly=""
            />
            <div>
              <ReferUserButton
                type="button"
                onClick={() => copyToClipboard(referralLink)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </ReferUserButton>
            </div>
          </div>
          <strong>or your Referral ID</strong>
          <br />
          <h4 style={convertInlineStyles("color:green;")}>
            {" "}
            {user?.username}
          </h4>{" "}
          <br />
          <h3
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <small>You were referred by</small>
            <br />
            <FontAwesomeIcon
              icon={faUser}
              style={{ fontWeight: 900, fontSize: 40 }}
            />
            <br />
            <small>null</small>
          </h3>
        </ReferUserCard>
        <h2 className="title1 text-dark text-left">Your Referrals.</h2>
        <Table
          tableHead={[
            "Client name",
            "Ref level",
            "Parent",
            "Client status",
            "Date registered",
          ]}
          data={referredUsers.map((client) => ({
            client_name: client.full_name,
            ref_level: "Direct",
            parent: user.full_name,
            client_status: "Active",
            date: new Date(user?.date_joined).toLocaleString(),
          }))}
        />
      </ReferUserCardWrapper>
    </ReferUserWrapper>
  );
};

export default ReferUser;
