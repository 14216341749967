import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { changePassword, logoutUser } from "../../../features/auth/AuthActions";
import { useNavigate } from "react-router-dom";
import FormError from "../../../components/FormError/FormError";
import Loading from "../../../components/Loading/Loading";

const PasswordSecurity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authLoading, authError } = useSelector((state) => state.auth);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password1: "",
      new_password2: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(),
      new_password1: Yup.string().required(),
      new_password2: Yup.string()
        .required("Repeat Password is Required")
        .oneOf([Yup.ref("new_password1"), null], "Password Must Match"),
    }),
    onSubmit: (values) => {
      dispatch(changePassword(values))
        .unwrap()
        .then(() =>
          dispatch(logoutUser({}))
            .unwrap()
            .then(() => navigate("/login"))
        )
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },
  });

  return (
    <PSFormWrapper>
      <PSForm onSubmit={formik.handleSubmit}>
        <PSFormGrid>
          <PSFormGroup>
            <label htmlFor="old_password">Old Password</label>
            <PSInput
              type="password"
              id="old_password"
              name="old_password"
              onChange={formik.handleChange}
              value={formik.values.old_password}
            />
            {formik.errors.old_password ? (
              <FormError>{formik.errors.old_password}</FormError>
            ) : null}
            <FormError>{authError?.old_password}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="new_password1">New Password1</label>
            <PSInput
              type="password"
              id="new_password1"
              name="new_password1"
              onChange={formik.handleChange}
              value={formik.values.new_password1}
            />
            {formik.errors.new_password1 ? (
              <FormError>{formik.errors.new_password1}</FormError>
            ) : null}
            <FormError>{authError?.new_password1}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="new_password2">New Password2</label>
            <PSInput
              type="password"
              id="new_password2"
              name="new_password2"
              onChange={formik.handleChange}
              value={formik.values.new_password2}
            />
            {formik.errors.new_password2 ? (
              <FormError>{formik.errors.new_password2}</FormError>
            ) : null}
            <FormError>{authError?.new_password2}</FormError>
          </PSFormGroup>
        </PSFormGrid>
        <PSSubmitBtn type="submit">
          {authLoading ? <Loading /> : "Update Password"}
        </PSSubmitBtn>
      </PSForm>
    </PSFormWrapper>
  );
};

const PSFormWrapper = styled.div``;

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(2, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSInput = styled.input`
  height: 40px;
  padding-inline: 5px;
  outline: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 1px solid #ced4da;
`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default PasswordSecurity;
