import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const TopNavBarStyleContainer = styled.nav`
  background: #fff;
  min-height: 60px;
  width: ${({ ShowSideNavBar }) =>
    ShowSideNavBar ? "calc(100% -  250px)" : "100%"};
  left: ${({ ShowSideNavBar }) => (ShowSideNavBar ? "250px" : "")};
  position: fixed;
  z-index: 1001;
  box-shadow: 0 0 5px rgba(18, 23, 39, 0.5);
  user-select: none;

  @media only screen and (min-width: 991px) {
    width: 100%;
    left: 0px;
  }
`;

export const TopNavBarStyle = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  background-color: ${({ theme }) =>
    theme.theme === "light" ? " #1572e8" : theme.body};
  height: 62px;
  transition: all 0.3s;
`;

export const TopNavBarMenuBurger = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const TopNavBarMenuElipsis = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (min-width: 991px) {
    display: none;
  }
`;

export const TopNavBarMenuStyleDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 991px) {
    display: flex;
    align-items: center;
  }
`;

export const TopNavBarMenuStyleMobile = styled.div`
  position: relative;
  display: ${({ showTopNavBarMenu }) => (showTopNavBarMenu ? "flex" : "none")};
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
  background-color: ${({ theme }) =>
    theme.theme === "light" ? " #1572e8" : theme.body};
  height: 62px;
  transition: all 1s;

  &,
  &.topbar_open {
    transform: translate3d(0, 0, 0) !important;
  }

  &,
  &.topbar_close {
    transform: translate3d(0, -200px, 0) !important;
  }

  @media only screen and (min-width: 991px) {
    display: none;
  }
`;

export const Logo = styled(Link)`
  font-size: 27px;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`;
