import React from "react";
import { LoginStyle } from "./Login.styled";
import {
  AuthCard,
  AuthForm,
  AuthFormButton,
  AuthFormGroup,
  AuthFormInput,
  AuthFormLabel,
  AuthInputGroup,
  AuthSVG,
} from "../../../containers/Auth/Auth.styled";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginUser } from "../../../features/auth/AuthActions";
import FormError from "../../../components/FormError/FormError";
import Loading from "../../../components/Loading/Loading";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authLoading, authError } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(10, "Username must be less or equal to 10 characters")
        .min(5, `Username must be greater or equal to 5 characters`)
        .required("Username is required")
        .matches(/^\S*$/, "Username cannot contain spaces"),
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values))
        .unwrap()
        .then(() => navigate("/dashboard/"))
        .catch((error) => {
          if (error.non_field_errors) {
            console.log(error.non_field_errors);
          }
        });
    },
  });

  return (
    <LoginStyle>
      <AuthCard>
        <div>
          <h4>User Login</h4>
          <AuthForm onSubmit={formik.handleSubmit}>
            <FormError>{authError?.non_field_errors}</FormError>
            <AuthFormGroup>
              <AuthFormLabel>
                Your Username{" "}
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </AuthFormLabel>
              <AuthInputGroup>
                <AuthSVG
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </AuthSVG>
                <AuthFormInput
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Enter your username"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
                {formik.errors.username ? (
                  <FormError>{formik.errors.username}</FormError>
                ) : null}
              </AuthInputGroup>
            </AuthFormGroup>
            <AuthFormGroup>
              <AuthFormLabel>
                Password{" "}
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
              </AuthFormLabel>
              <AuthInputGroup>
                <AuthSVG
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                </AuthSVG>
                <AuthFormInput
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password ? (
                  <FormError>{formik.errors.password}</FormError>
                ) : null}
              </AuthInputGroup>
            </AuthFormGroup>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                height: 44,
              }}
            >
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    name="remember"
                  />
                  <AuthFormLabel style={{ marginBottom: 0 }} for="customCheck1">
                    Remember me
                  </AuthFormLabel>
                </div>
              </div>
              <p style={{ margin: 0 }}>
                <Link
                  to="/forgot-password"
                  style={{
                    color: "#3c4858",
                    fontWeight: 700,
                    fontSize: "13px",
                    textDecoration: "none",
                  }}
                >
                  Forgot password ?
                </Link>
              </p>
            </div>
            <AuthFormButton type="submit" disabled={authLoading}>
              {authLoading ? <Loading /> : "Sign in"}
            </AuthFormButton>
            <>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                <p style={{ marginBlockStart: "2.5rem" }}>
                  <small className="mr-2 text-dark">Don't have an account ?</small>{" "}
                  <Link
                    to="/register"
                    style={{
                      color: "#3c4858",
                      fontWeight: "bold",
                      textDecoration: "none",
                      transition: "all 0.5s ease",
                    }}
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                <p style={{ marginBlock: "1.5rem 0", lineHeight: "1.6" }}>
                  <small className="mr-2 text-dark">
                    © Copyright 2023 &nbsp; StakecornServices &nbsp; All Rights
                    Reserved.
                  </small>
                </p>
              </div>
            </>
          </AuthForm>
        </div>
      </AuthCard>
    </LoginStyle>
  );
};

export default Login;
