import styled from "styled-components";

export const MainPanel = styled.div`
  position: relative;
  width: ${({ ShowSideNavBar }) =>
    ShowSideNavBar ? "calc(100% -  250px)" : "100%"};
  height: calc(100vh - 62px);
  min-height: 100%;
  float: right;
  transition: all 0.3s;
  padding-top: 62px;

  @media only screen and (min-width: 990px) {
    width: calc(100% - 250px);
  }
`;

export const MainWrapper = styled.div`
  padding: 24px 15px;

  width: calc(100% - 30px);

  @media only screen and (min-width: 991px) {
    padding: 24px 32px;
    width: calc(100% - 64px);
  }
`;
