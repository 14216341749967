import React from "react";
import { TradingCard, TradingWrapper } from "./Trading.styled";
import { convertInlineStyles } from "../../utils/utils";
import Table from "../../components/Table/Table";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Trading = () => {
  const navigate = useNavigate();
  return (
    <TradingWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Trading
      </h1>
      <TradingCard>
        <h2
          style={convertInlineStyles(
            "color: #343a40; line-height: 1.4; font-size: 1.35rem; font-weight: 500;"
          )}
        >
          StakecornServices Account manager
        </h2>
        <br />
        <div clas="well">
          <p className="text-justify text-dark">
            Don't have time to trade or learn how to trade? Our Account
            Management Service is The Best Profitable Trading Option for you, We
            can help you to manage your account in the financial MARKET with a
            simple subscription model.
            <br />
            <small>Terms and Conditions apply</small>
            <br />
            Reach us at support@stakecornservices.finance for more info.
          </p>
        </div>
        <br />
        <div>
          <PSSubmitBtn onClick={() => navigate("/dashboard/trading-plans")}>
            View Trading Plans
          </PSSubmitBtn>
        </div>
      </TradingCard>
      <Table
        data={[]}
        tableHead={[
          "MT4 ID",
          "MT4 Password",
          "Account Type",
          "Currency",
          "Leverage",
          "Server",
          "Duration",
          "Submitted at",
          "Expiring at",
          "Status",
          "Action",
        ]}
      />

      <div>
        <h3
          style={convertInlineStyles(
            "line-height: 1.4; font-size: 1.1625rem; font-weight: 500; color: #b7bcc6;"
          )}
        >
          Connect to your trading account
        </h3>
        <div className="p-4 col" style={convertInlineStyles("")}>
          <iframe
            src="https://trade.mql5.com/trade"
            name="WebTrader"
            title="Subscription Trade"
            frameborder="0"
            style={convertInlineStyles(
              "display: block; border: none; height: 76vh; width: 100%; padding: 1.5rem!important;"
            )}
          ></iframe>
        </div>
      </div>
    </TradingWrapper>
  );
};

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default Trading;
