import styled from "styled-components";

export const AuthStyle = styled.div`
  background-color: #e4f4ff !important;
  border: 1px solid rgba(204, 220, 252, 0.1) !important;
  min-height: calc(100vh - 120px);
  padding: 60px 0;
`;

export const AuthCard = styled.div`
  width: 100%;
  max-width: 450px;
  border: 1px solid #edf2f9;
  background-color: #fff;
  padding: 20px 10px;
  margin: 0 10px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  font-size: 15px;

  & > div {
    padding: 1.5rem;
    min-height: 1px;
  }

  & > div > h4 {
    text-align: center !important;
    margin-bottom: 0.75rem;
    margin-top: 0;
    line-height: 1.5;
    font-weight: 600;
    font-size: 24px !important;
    color: #2980b9 !important;
  }
`;

export const AuthForm = styled.form`
  margin-top: 1.5rem !important;
  box-sizing: border-box;
  display: block;
`;

export const AuthFormLabel = styled.label`
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
  color: #2980b9;
`;

export const AuthFormGroup = styled.div`
  margin-bottom: 20px;
`;

export const AuthInputGroup = styled.div`
  position: relative;
`;

export const AuthSVG = styled.svg`
  position: absolute;
  top: 19px;
  left: 23px;
  height: 16px;
  width: 16px;
  stroke-width: 1.8;
  overflow: hidden;
  vertical-align: middle;
  color: #2980b9 !important;
`;

export const AuthFormInput = styled.input`
  display: block;
  width: calc(100% - 3rem);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding-left: 3rem !important;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #2f55d4;
    outline: 0;
  }
`;

export const AuthFormSelect = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding-left: 3rem !important;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #2f55d4;
    outline: 0;
  }
`;

export const AuthFormButton = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 13px 40px !important;
  background-color: #2980b9;
  border-color: #2980b9 !important;
  border: 1px solid #2980b9 !important;
  color: #fff !important;

  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
  border-bottom-left-radius: 0;

  box-shadow: 0 3px 5px 0 rgba(39, 111, 179, 0.3);
  /* outline: none; */
`;
