import React, { useState } from "react";
import {
  AccountSettingsCard,
  AccountSettingsWrapper,
} from "./AccountSettings.styled";
import { slugify } from "../../helper";
import PersonalSettings from "./PersonalSettings/PersonalSettings";
import WithdrawalSettings from "./WithdrawalSettings/WithdrawalSettings";
import PasswordSecurity from "./PasswordSecurity/PasswordSecurity";
import OtherSettings from "./OtherSettings/OtherSettings";

const AccountSettings = () => {
  const settingsTabs = [
    {
      name: "Personal Settings",
      handleClick: () => setCurrentTab(slugify("Personal Settings")),
    },
    {
      name: "Withdrawal Settings",
      handleClick: () => setCurrentTab(slugify("Withdrawal Settings")),
    },
    {
      name: "Password/Security",
      handleClick: () => setCurrentTab(slugify("Password/Security")),
    },
    {
      name: "Other Settings",
      handleClick: () => setCurrentTab(slugify("Other Settings")),
    },
  ];
  const [currentTab, setCurrentTab] = useState(slugify(settingsTabs[0].name));
  return (
    <AccountSettingsWrapper>
      <AccountSettingsCard>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",
            marginBlock: "20px",
          }}
        >
          {settingsTabs.map((tab, index) => (
            <div
              key={index}
              onClick={tab.handleClick}
              style={{
                border: ".5px solid #1472e8",
                backgroundColor: `${
                  currentTab === slugify(tab.name) ? "#1472e8" : ""
                }`,
                color: `${currentTab === slugify(tab.name) ? "#fff" : ""}`,
                padding: "15px",
                cursor: "pointer",
                width: `100% / ${settingsTabs.length}px`,
              }}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <>
          {currentTab === slugify("Personal Settings") ? (
            <PersonalSettings />
          ) : currentTab === slugify("Withdrawal Settings") ? (
            <WithdrawalSettings />
          ) : currentTab === slugify("Password/Security") ? (
            <PasswordSecurity />
          ) : currentTab === slugify("Other Settings") ? (
            <OtherSettings />
          ) : null}
        </>
      </AccountSettingsCard>
    </AccountSettingsWrapper>
  );
};

export default AccountSettings;
