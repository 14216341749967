import styled from 'styled-components';

export const BalanceCardWrapper = styled.div``

export const BalanceCardd = styled.div`
  position: relative;
  word-wrap: break-word;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem !important;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({theme}) => theme.body};
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  border: 0;
`;

export const BalanceCardSpan = styled.span`
  color: ${({ iconColor }) => iconColor};
  background: ${({ iconBGColor }) => iconBGColor};
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  margin-right: 1rem !important;
`;

export const BalanceCardH5 = styled.h5`
  color: #343a40 !important;
  margin-bottom: 0.25rem !important;
  line-height: 1.4;
  font-size: 0.9125rem;
  margin-top: 0;
`;
