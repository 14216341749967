import React from "react";
import styled from "styled-components";

const Info = ({ children, variant, style }) => {
  console.log(variant);
  return (
    <InfoWrapper variant={variant} style={style}>
      {children}
    </InfoWrapper>
  );
};

export const InfoWrapper = styled.div`
  border: 0;
  position: relative;
  padding: 0.95rem 1.25rem;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  color: inherit;
  background-color: ${({ theme }) => theme.body};
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-left: ${({ variant }) =>
    variant === "danger" ? "4px solid tomato" : "4px solid #31ce36"};

  margin-bottom: 1rem !important;
`;

export default Info;
