import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SideNavBarStyle = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  display: ${({ ShowSideNavBar }) => (ShowSideNavBar ? "block" : "none")};
  z-index: 10000;
  color: ${({ theme }) => theme.text};
  font-weight: 200;
  background: ${({ theme }) => theme.body};
  -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  transition: all 0.3s;

  @media only screen and (min-width: 990px) {
    margin-top: 62px;
    display: block;
  }
`;

export const ScrollWrapper = styled.div`
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 4;
  padding-bottom: 100px;
  transition: all 0.3s;
`;

export const Caret = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: auto;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
`;

export const User = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 12.5px;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const NavUl = styled.ul`
  display: block;
  padding: 0;
  /* padding-bottom: 80px; */
  overflow-y: scroll;
`;

export const NavLi = styled.li`
  padding: 5px 15px;
  display: list-item;

  /* @media only screen and (min-width: 990px) {
    &:nth-child(2),
    &:nth-child(3) {
      display: none;
    }
  } */
`;

export const NavDDUlLi = styled.li`
  padding: 0 15px;
  display: list-item;

  @media only screen and (min-width: 990px) {
  }
`;

export const NavLiDD = styled.div`
  text-decoration: none;
  color: #8d9498;
  padding: 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(199, 199, 199, 0.2) !important;
    color: #575962 !important;
  }

  & > svg {
    /* color: #8d9498; */
    margin-right: 15px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
    float: left;
    font-size: 18px;
    line-height: 30px;
  }
  & > p {
    font-size: 14px;
    margin-right: 5px;
    white-space: nowrap;
    /* width: 100%; */
    font-size: 14px;
    font-weight: 700;
    position: relative;
    margin-block: 6px;
  }
`;
export const NavLiLink = styled(NavLink)`
  text-decoration: none;
  color: #8d9498;
  padding: 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(199, 199, 199, 0.2) !important;
    color: #575962 !important;
  }

  &.active {
    color: ${({ theme }) =>
      theme.theme === "light" ? "#fff !important" : "#1a2035 !important"};
    background: #1572e8 !important;
    background: ${({ theme }) =>
      theme.theme === "light" ? "#1572e8 !important" : "#fff !important"};
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1),
      4px 4px 15px -5px rgba(21, 114, 232, 0.4) !important;
  }

  & > svg {
    /* color: #8d9498; */
    margin-right: 15px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
    float: left;
    font-size: 18px;
    line-height: 30px;
  }
  & > p {
    font-size: 14px;
    margin-right: 5px;
    white-space: nowrap;
    /* width: 100%; */
    font-size: 14px;
    font-weight: 700;
    position: relative;
    margin-block: 6px;
  }
`;
