import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const CryptoExchangeWrapper = styled(MainWrapper)``;

export const ExchangeWrapper = styled.div`
  display: block;

  @media only screen and (min-width: 991px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const TradingViewWrapper = styled.div`
  width: "100%";

  @media only screen and (min-width: 991px) {
    width: 65%;
  }
`;

export const ExchangeFormWrapper = styled.div`
  width: 100%;

  @media only screen and (min-width: 991px) {
    width: 30%;
  }
`;

export const ExchangeForm = styled.form`
  width: 100%;
`;

export const ExchangeFormGroup = styled.div`
  margin-bottom: 0;
  padding: 10px;
`;

export const ExchangeFormInput = styled.input`
  font-size: 14px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border-color: #ebedf2;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  height: inherit !important;

  display: block;
  width: calc(100% - 2rem);
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: transparent;
  }
`;

export const ExchangeFormSelect = styled.select`
  font-size: 14px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border-color: #ebedf2;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  height: inherit !important;

  display: block;
  width: 100%;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: transparent;
  }
`;

export const ExchangeFormButton = styled.button`
  width: 100%;
  background-color: #31ce36;
  color: #fff;
  font-size: 11px;
  padding: 7px 13px;
  opacity: 1;
  border-radius: 4px;
  border-bottom-left-radius: 0;

  user-select: none;
  border: 1px solid transparent;

  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;

  cursor: pointer;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const BalanceCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
  }
`;
