import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const AccountSettingsWrapper = styled(MainWrapper)``;

export const AccountSettingsCard = styled.div`
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  margin-inline: auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;

  padding: 2rem;
`;
