import React from "react";
import { DepositCard, DepositWrapper } from "./Deposit.styled";
import { convertInlineStyles } from "../../utils/utils";
import styled from "styled-components";
import FormError from "../../components/FormError/FormError";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../components/Info/Info";

const Deposit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentMethods = [
    {
      name: "Binance Coin (BNB)",
      // symbol: "BNB",
      image: "https://cryptologos.cc/logos/bnb-bnb-logo.png",
    },
    {
      name: "Ripple (XRP)",
      // symbol: "XRP",
      image: "https://cryptologos.cc/logos/xrp-xrp-logo.png",
    },
    {
      name: "Tron (TRX)",
      // symbol: "TRX",
      image: "https://cryptologos.cc/logos/tron-trx-logo.png",
    },
    {
      name: "Tether (USDT)",
      // symbol: "USDT",
      image: "https://cryptologos.cc/logos/tether-usdt-logo.png",
    },
    {
      name: "Ethereum (ETH)",
      // symbol: "ETH",
      image: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
    },
    {
      name: "Bitcoin (BTC)",
      // symbol: "BTC",
      image: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: null,
      payment_method: "Binance Coin (BNB)",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required(),
      // .min(
      //   10000,
      //   `minimum deposit is ${(10000).toLocaleString("en-US", {
      //     style: "currency",
      //     currency: "USD",
      //   })}`
      // ),
      payment_method: Yup.string().required(),
    }),
    onSubmit: (values) => {
      navigate("/dashboard/payment", { state: { ...values } });
    },
  });

  return (
    <DepositWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Fund Your Account
      </h1>
      {location.state?.error && (
        <Info variant={"danger"}>{location.state?.error}</Info>
      )}
      <DepositCard>
        <PSFormWrapper>
          <PSForm onSubmit={formik.handleSubmit}>
            <h3>Enter Amount</h3>
            <PSFormGrid>
              <PSFormGroup>
                <PSInput
                  type="number"
                  id="amount"
                  name="amount"
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  placeholder="Enter Amount"
                />
                {formik.errors.amount ? (
                  <FormError>{formik.errors.amount}</FormError>
                ) : null}
              </PSFormGroup>
            </PSFormGrid>
            <h3>Choose Payment Method from the list below</h3>
            <PSFormGrid>
              {paymentMethods.map((data, index) => (
                <PSFormGroup key={index}>
                  <DepositPaymentMethodCard
                    active={data.name.includes(formik.values.payment_method)}
                    onClick={() =>
                      formik.setFieldValue("payment_method", data.name)
                    }
                  >
                    <img
                      src={data.image}
                      alt={data.name}
                      height={20}
                      width={20}
                    />
                    <span style={{ marginInlineStart: "20px" }}>
                      {data.name}
                    </span>
                  </DepositPaymentMethodCard>
                </PSFormGroup>
              ))}
            </PSFormGrid>
            <PSSubmitBtn>Proceed to Payment</PSSubmitBtn>
          </PSForm>
        </PSFormWrapper>
      </DepositCard>
    </DepositWrapper>
  );
};

const PSFormWrapper = styled.div``;

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 10px;
  max-width: 100% !important;

  @media only screen and (min-width: 991px) {
    gap: 10px 30px;
    width: 70% !important;
    grid-template-columns: repeat(2, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSInput = styled.input`
  height: 40px;
  padding-inline: 5px;
  outline: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border: .1px solid #ced4da;
  background-color: ${({ theme, active }) => (active ? "#1472e8" : theme.body)};
`;

const DepositPaymentMethodCard = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme, active }) => (active ? "#1472e8" : theme.body)};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;
  padding: 1.5rem;
  cursor: pointer;
`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default Deposit;
