import React from "react";
import { StakingPlanWrapper } from "./StakingPlan.styled";
import { convertInlineStyles } from "../../utils/utils";
import PricingTable from "../../components/PricingTable/PricingTable";

const StakingPlan = () => {
  return (
    <StakingPlanWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Staking Plans
      </h1>
      <PricingTable
        pricingData={[
          {
            currency: "Bitcoin",
            price_tag: 0.022,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "Ethereum",
            price_tag: 0.042,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "USDT",
            price_tag: 0.046,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "BNB T+1 DeFi Farm",
            price_tag: 0.036,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "XRP T +1DeFi Farm",
            price_tag: 0.02,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "TRX T +3 staking",
            price_tag: 0.022,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: true,
          },
          {
            currency: "ADA",
            price_tag: 0.022,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "Jasmy",
            price_tag: 0.022,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
          {
            currency: "S & P",
            price_tag: 0.022,
            min_possible_stake: 500,
            max_possible_stake: 200,
            min_yield: 33,
            max_yield: 42,
            gift_bonus: 300,
            duration: 52,
            is_hot: false,
          },
        ]}
      />
    </StakingPlanWrapper>
  );
};

export default StakingPlan;
