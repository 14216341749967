import React, { useEffect } from "react";
import { TradingHistoryWrapper } from "./TradingHistory.styled";
import Table from "../../components/Table/Table";
import { convertInlineStyles } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getProfits } from "../../features/profit/ProfitlActions";

const TradingHistory = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { profits, profitsIsLoading } = useSelector((state) => state.profit);

  useEffect(() => {
    dispatch(getProfits({ owner_id: user.id }));
  }, [dispatch, user]);
  return (
    <TradingHistoryWrapper>
      <div
        style={convertInlineStyles(
          "margin-bottom: 1.5rem; margin-top: 0.5rem;"
        )}
      >
        <h1
          style={convertInlineStyles(
            "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
          )}
        >
          Your ROI history
        </h1>
      </div>
      {!profitsIsLoading && (
        <Table
          tableHead={["Plan", "Amount", "Type", "Date"]}
          data={profits.map((profit) => {
            return {
              plan: profit?.plan,
              amount: `$${profit?.amount}`,
              type: profit?.type,
              date: new Date(profit?.date).toLocaleString(),
            };
          })}
        />
      )}
    </TradingHistoryWrapper>
  );
};

export default TradingHistory;
