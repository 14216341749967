export const lightTheme = {
  theme: "light",
  body: "#FFF",
  text: "#363537",
  toggleBorder: "#FFF",
  background: "#363537",
  card: {
    background: "#fff",
  }
};

export const darkTheme = {
  theme: "dark",
  body: "#1a2035",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#999",
  card: {
    background: "#1a2035",
  }
};
