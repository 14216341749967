import React, { useState } from "react";
import {
  AuthCard,
  AuthForm,
  AuthFormButton,
  AuthFormGroup,
  AuthFormInput,
  AuthFormLabel,
  AuthFormSelect,
  AuthInputGroup,
  AuthSVG,
} from "../../../containers/Auth/Auth.styled";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RegisterStyle } from "./Register.styled";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerUser } from "../../../features/auth/AuthActions";
import FormError from "../../../components/FormError/FormError";
import Loading from "../../../components/Loading/Loading";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const referral = query.get("referral");
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);

  const { authLoading, authError } = useSelector((state) => state.auth);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      full_name: "",
      email: "",
      phone_number: "",
      password1: "",
      password2: "",
      nationality: "",
      referral: referral,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(10, "Username must be less or equal to 10 characters")
        .min(5, `Username must be greater or equal to 5 characters`)
        .required("Username is required")
        .matches(/^\S*$/, "Username cannot contain spaces"),
      full_name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone_number: Yup.number().required(),
      password1: Yup.string().required(),
      password2: Yup.string()
        .required("Repeat Password is Required")
        .oneOf([Yup.ref("password1"), null], "Password Must Match"),
      nationality: Yup.string().required(),
      // referral: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values))
        .unwrap()
        .then(() => {
          setIsSignupSuccess(true);
        });
    },
  });
  return (
    <RegisterStyle>
      {isSignupSuccess ? (
        <AuthCard
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            padding: "50px 25px",
          }}
        >
          <svg
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="100" cy="100" r="100" fill="#0B8C56" />
            <path
              d="M133.172 73.1716C134.734 71.6095 137.266 71.6095 138.828 73.1716C140.391 74.7337 140.391 77.2663 138.828 78.8284L90.8284 126.828C89.2663 128.391 86.7337 128.391 85.1716 126.828L65.1716 106.828C63.6095 105.266 63.6095 102.734 65.1716 101.172C66.7337 99.6095 69.2663 99.6095 70.8284 101.172L88 118.343L133.172 73.1716Z"
              fill="white"
            />
            <mask
              id="mask0_113_6021"
              maskUnits="userSpaceOnUse"
              x="64"
              y="72"
              width="76"
              height="56"
            >
              <path
                d="M133.172 73.1716C134.734 71.6095 137.266 71.6095 138.828 73.1716C140.391 74.7337 140.391 77.2663 138.828 78.8284L90.8284 126.828C89.2663 128.391 86.7337 128.391 85.1716 126.828L65.1716 106.828C63.6095 105.266 63.6095 102.734 65.1716 101.172C66.7337 99.6095 69.2663 99.6095 70.8284 101.172L88 118.343L133.172 73.1716Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_113_6021)">
              <rect x="40" y="40" width="120" height="120" fill="white" />
            </g>
          </svg>

          <h2 style={{ color: "#4B9DD5", marginBlock: "70px" }}>
            Successful Sign Up
          </h2>
          <AuthFormButton
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </AuthFormButton>
        </AuthCard>
      ) : (
        <AuthCard>
          <div>
            <h4>Create an Account</h4>
            <AuthForm onSubmit={formik.handleSubmit}>
              {/* UserName */}
              <AuthFormGroup>
                <AuthFormLabel>
                  UserName{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-user fea icon-sm icons"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </AuthSVG>
                  <AuthFormInput
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter Unique Username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                  />
                  {formik.errors.username ? (
                    <FormError>{formik.errors.username}</FormError>
                  ) : null}
                  <FormError>{authError?.username}</FormError>
                </AuthInputGroup>
              </AuthFormGroup>
              {/* FullName */}
              <AuthFormGroup>
                <AuthFormLabel>
                  FullName{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-user-check fea icon-sm icons"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <polyline points="17 11 19 13 23 9"></polyline>
                  </AuthSVG>
                  <AuthFormInput
                    type="text"
                    name="full_name"
                    id="full_name"
                    placeholder="Enter FullName"
                    onChange={formik.handleChange}
                    value={formik.values.full_name}
                  />
                  {formik.errors.full_name ? (
                    <FormError>{formik.errors.full_name}</FormError>
                  ) : null}
                  <FormError>{authError?.full_name}</FormError>
                </AuthInputGroup>
              </AuthFormGroup>
              {/* Your Email */}
              <AuthFormGroup>
                <AuthFormLabel>
                  Your Email{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </AuthSVG>
                  <AuthFormInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder="name@example.com"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email ? (
                    <FormError>{formik.errors.email}</FormError>
                  ) : null}
                  {/* <FormError>
                  {authError?.includes("users_user.email")
                    ? "Email already exist"
                    : null}
                </FormError> */}
                </AuthInputGroup>
              </AuthFormGroup>
              {/* Phone Number */}
              <AuthFormGroup>
                <AuthFormLabel>
                  Phone Number{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-phone fea icon-sm icons"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                  </AuthSVG>
                  <AuthFormInput
                    type="phone"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Enter Phone Number"
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                  />
                  {formik.errors.phone_number ? (
                    <FormError>{formik.errors.phone_number}</FormError>
                  ) : null}
                  <FormError>{authError?.phone_number}</FormError>
                </AuthInputGroup>
              </AuthFormGroup>
              {/* Password1 */}
              <AuthFormGroup>
                <AuthFormLabel>
                  Password{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </AuthSVG>
                  <AuthFormInput
                    type="password"
                    name="password1"
                    id="password1"
                    placeholder="Enter Password"
                    onChange={formik.handleChange}
                    value={formik.values.password1}
                  />
                  {formik.errors.password1 ? (
                    <FormError>{formik.errors.password1}</FormError>
                  ) : null}
                  <FormError>{authError?.password1}</FormError>
                </AuthInputGroup>
              </AuthFormGroup>
              {/* Password2 */}
              <AuthFormGroup>
                <AuthFormLabel>
                  Confirm Password{" "}
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                  </AuthSVG>
                  <AuthFormInput
                    type="password"
                    name="password2"
                    id="password2"
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    value={formik.values.password2}
                  />
                  {formik.errors.password2 ? (
                    <FormError>{formik.errors.password2}</FormError>
                  ) : null}
                </AuthInputGroup>
              </AuthFormGroup>
              <AuthFormGroup>
                <AuthFormLabel>
                  Country
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-map-pin fea icon-sm icons"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </AuthSVG>
                  <AuthFormSelect
                    name="nationality"
                    id="nationality"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.nationality}
                  >
                    <option selected disabled>
                      Choose Country
                    </option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua &amp; Barbuda">
                      Antigua &amp; Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia &amp; Herzegovina">
                      Bosnia &amp; Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote D'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre &amp; Miquelon">
                      St Pierre &amp; Miquelon
                    </option>
                    <option value="St Vincent &amp; Grenadines">
                      St Vincent &amp; Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome &amp; Principe">
                      Sao Tome &amp; Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad &amp; Tobago">
                      Trinidad &amp; Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks &amp; Caicos Is">
                      Turks &amp; Caicos Is
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis &amp; Futana Is">
                      Wallis &amp; Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>{" "}
                  </AuthFormSelect>
                  {formik.errors.nationality ? (
                    <FormError>{formik.errors.nationality}</FormError>
                  ) : null}
                </AuthInputGroup>
              </AuthFormGroup>
              <AuthFormGroup>
                <AuthFormLabel>
                  Referral ID
                  {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                </AuthFormLabel>
                <AuthInputGroup>
                  <AuthSVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-user fea icon-sm icons"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </AuthSVG>
                  <AuthFormInput
                    type="text"
                    name="referral"
                    id="referral"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.referral}
                  />
                  {formik.errors.referral ? (
                    <FormError>{formik.errors.referral}</FormError>
                  ) : null}
                </AuthInputGroup>
              </AuthFormGroup>
              <AuthFormButton type="submit" disabled={authLoading}>
                {authLoading ? <Loading /> : "Register"}
              </AuthFormButton>
              <>
                <div style={{ textAlign: "center", fontSize: "15px" }}>
                  <p style={{ marginBlockStart: "2.5rem" }}>
                    <small className="mr-2 text-dark">
                      Already have an account ?
                    </small>{" "}
                    <Link
                      to="/login"
                      style={{
                        color: "#3c4858",
                        fontWeight: "bold",
                        textDecoration: "none",
                        transition: "all 0.5s ease",
                      }}
                    >
                      Login
                    </Link>
                  </p>
                </div>
                <div style={{ textAlign: "center", fontSize: "15px" }}>
                  <p style={{ marginBlock: "1.5rem 0", lineHeight: "1.6" }}>
                    <small className="mr-2 text-dark">
                      © Copyright 2023 &nbsp; StakecornServices &nbsp; All
                      Rights Reserved.
                    </small>
                  </p>
                </div>
              </>
            </AuthForm>
          </div>
        </AuthCard>
      )}
    </RegisterStyle>
  );
};

export default Register;
