import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const createWithdrawalSettings = createAsyncThunk(
  "create-withdrawal-settings",
  async (
    {
      owner,
      bank_name,
      account_name,
      account_number,
      swift_code,
      bitcoin,
      ethereum,
      litecoin,
    },
    thunkApi
  ) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.post(
        `${endPoint}/withdrawals-settings/`,
        {
          owner,
          bank_name,
          account_name,
          account_number,
          swift_code,
          bitcoin,
          ethereum,
          litecoin,
        },
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateWithdrawalSettings = createAsyncThunk(
  "update-withdrawal-settings",
  async (
    {
      owner,
      bank_name,
      account_name,
      account_number,
      swift_code,
      bitcoin,
      ethereum,
      litecoin,
    },
    thunkApi
  ) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.put(
        `${endPoint}/withdrawals-settings/${owner}/`,
        {
          owner,
          bank_name,
          account_name,
          account_number,
          swift_code,
          bitcoin,
          ethereum,
          litecoin,
        },
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getWithdrawalSettings = createAsyncThunk(
  "get-withdrawal-settings",
  async ({ owner_id }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${endPoint}/withdrawals-settings/${owner_id}/`,
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getWithdrawals = createAsyncThunk(
  "get-withdrawals",
  async ({ owner_id }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${endPoint}/withdrawals/?owner=${owner_id}`,
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const withdrawal = createAsyncThunk(
  "withdrawal",
  async ({ owner, amount_requested, receive_mode }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.post(
        `${endPoint}/withdrawals/`,
        {owner, amount_requested, receive_mode},
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
