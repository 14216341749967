import React from "react";
import {
  SupportCard,
  SupportTextInput,
  SupportWrapper,
} from "./Support.styled";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import styled from "styled-components";

const Support = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <SupportWrapper>
      <SupportCard>
        <div>
          <h1 className="title1 text-dark">StakecornServices Support</h1>
          <div className="sign-up-row widget-shadow text-dark">
            <h4 className="text-dark">
              For inquiries, suggestions or complains. Mail us
            </h4>
            <h1 className="mt-3 text-primary">
              <a href="mailto:support@stakecornservices.finance">
                support@stakecornservices.finance
              </a>
            </h1>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginBlockStart: "20px",
          }}
        >
          <form style={{ width: "70%" }}>
            <input type="hidden" name="name" value={user?.full_name} />
            <div className="form-group">
              <input type="hidden" name="email" value={user?.email} />
            </div>

            <div className="form-group">
              <h5 for="" className="text-dark">
                Message<span style={{ color: "red" }}>*</span>
              </h5>
              <SupportTextInput
                name="message"
                rows="5"
                style={{ width: "100%" }}
              ></SupportTextInput>
            </div>
            <div className="">
              <PSSubmitBtn type="submit" onClick={(e) => e.preventDefault()}>
                {false ? <Loading /> : "Send"}
              </PSSubmitBtn>
            </div>
          </form>
        </div>
      </SupportCard>
    </SupportWrapper>
  );
};

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default Support;
