import React from "react";
import { FooterWrapper } from "./Footer.styled";

const Footer = () => {
  return (
    <FooterWrapper>
      <div>
        <div>
          <p style={{ fontSize: "15px", lineHeight: 1.82 }}>
            All Rights Reserved © StakecornServices 2023
          </p>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
