import { createSlice } from "@reduxjs/toolkit";
import {
  createWithdrawalSettings,
  updateWithdrawalSettings,
  getWithdrawalSettings,
  getWithdrawals,
  withdrawal,
} from "./withdrawalActions";

const initialState = {
  withdrawalSettings: {},
  createWithdrawalSettingsError: null,
  createWithdrawalSettingsIsLoading: false,
  updateWithdrawalSettingsError: null,
  updateWithdrawalSettingsIsLoading: false,
  getWithdrawalSettingsError: null,
  getWithdrawalSettingsIsLoading: false,
  withdrawals: [],
  withdrawal: {},
  withdrawalError: null,
  withdrawalIsLoading: false,
};

const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {},
  extraReducers: {
    [createWithdrawalSettings.fulfilled]: (state, payload) => {
      state.createWithdrawalSettingsIsLoading = false;
      state.withdrawalSettings = payload;
    },
    [createWithdrawalSettings.rejected]: (state, payload) => {
      state.createWithdrawalSettingsIsLoading = false;
      state.createWithdrawalSettingsError = payload;
    },
    [createWithdrawalSettings.pending]: (state) => {
      state.createWithdrawalSettingsIsLoading = true;
    },
    [updateWithdrawalSettings.fulfilled]: (state, payload) => {
      state.updateWithdrawalSettingsIsLoading = false;
      state.withdrawalSettings = payload;
    },
    [updateWithdrawalSettings.rejected]: (state, payload) => {
      state.updateWithdrawalSettingsIsLoading = false;
      state.updateWithdrawalSettingsError = payload;
    },
    [updateWithdrawalSettings.pending]: (state) => {
      state.updateWithdrawalSettingsIsLoading = true;
    },
    [getWithdrawalSettings.fulfilled]: (state, { payload }) => {
      state.getWithdrawalSettingsIsLoading = false;
      state.withdrawalSettings = payload.data;
    },
    [getWithdrawalSettings.rejected]: (state, payload) => {
      state.getWithdrawalSettingsIsLoading = false;
      state.getWithdrawalSettingsError = payload;
    },
    [getWithdrawalSettings.pending]: (state) => {
      state.getWithdrawalSettingsIsLoading = true;
    },
    [withdrawal.fulfilled]: (state, { payload }) => {
      state.withdrawalIsLoading = false;
      state.withdrawal = payload.data;
    },
    [withdrawal.rejected]: (state, payload) => {
      state.withdrawalIsLoading = false;
      state.withdrawalError = payload;
    },
    [withdrawal.pending]: (state) => {
      state.withdrawalIsLoading = true;
    },
    [getWithdrawals.fulfilled]: (state, { payload }) => {
      state.getWithdrawalSettingsIsLoading = false;
      state.withdrawals = payload.data;
    },
    [getWithdrawals.rejected]: (state, payload) => {
      state.getWithdrawalSettingsIsLoading = false;
      state.getWithdrawalSettingsError = payload;
    },
    [getWithdrawals.pending]: (state) => {
      state.getWithdrawalSettingsIsLoading = true;
    },
  },
});

export default withdrawalSlice.reducer;
