import React, { useEffect, useState } from "react";
import {
  Logo,
  TopNavBarMenuBurger,
  TopNavBarMenuElipsis,
  TopNavBarMenuStyleMobile,
  TopNavBarMenuStyleDesktop,
  TopNavBarStyle,
  TopNavBarStyleContainer,
} from "./TopNavBar.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEllipsisVertical,
  faLayerGroup,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import ThemeTogglerButton from "./ThemeTogglerButton";

const TopNavBar = ({
  setShowSideNavBar,
  ShowSideNavBar,
  themeToggler,
  themeState,
}) => {
  const [showTopNavBarMenu, setShowTopNavBarMenu] = useState(false);
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <TopNavBarStyleContainer ShowSideNavBar={ShowSideNavBar}>
      <TopNavBarStyle>
        <TopNavBarMenuBurger
          icon={faBars}
          onClick={() => {
            setShowSideNavBar(!ShowSideNavBar);
          }}
        />
        <Logo
          to="/dashboard"
          onClick={() => {
            setShowSideNavBar(false);
          }}
        >
          StakecornServices
        </Logo>
        <TopNavBarMenuElipsis
          icon={faEllipsisVertical}
          onClick={() => setShowTopNavBarMenu(!showTopNavBarMenu)}
        />
        <TopNavBarMenuStyleDesktop>
          <ThemeTogglerButton
            handleClick={themeToggler}
            themeState={themeState}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginInline: "10px",
            }}
          >
            <FontAwesomeIcon
              icon={faLayerGroup}
              style={{ color: "#fff", fontSize: 19 }}
            />
            <strong
              style={{ fontSize: "8px", fontWeight: "600", color: "#fff" }}
            >
              KYC
            </strong>
          </div>
          <FontAwesomeIcon
            icon={faUser}
            style={{ color: "#fff", fontSize: 19, cursor: "pointer" }}
            onClick={() => setShowDropDownMenu(!showDropDownMenu)}
          />
          <DropDownMenu
            showDropDownMenu={showDropDownMenu}
            setShowDropDownMenu={setShowDropDownMenu}
            setShowTopNavBarMenu={setShowTopNavBarMenu}
          />
        </TopNavBarMenuStyleDesktop>
      </TopNavBarStyle>
      <TopNavBarMenuStyleMobile
        className={showTopNavBarMenu ? "topbar_open" : "topbar_close"}
        showTopNavBarMenu={showTopNavBarMenu}
        ShowSideNavBar={ShowSideNavBar}
      >
        <div id="google_translate_element" style={{display: "block"}}></div>
        <ThemeTogglerButton
          handleClick={themeToggler}
          themeState={themeState}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginInlineEnd: "10px",
          }}
        >
          <FontAwesomeIcon
            icon={faLayerGroup}
            style={{ color: "#fff", fontSize: 19 }}
          />
          <strong style={{ fontSize: "8px", fontWeight: "600", color: "#fff" }}>
            KYC
          </strong>
        </div>
        <FontAwesomeIcon
          icon={faUser}
          style={{ color: "#fff", fontSize: 19, cursor: "pointer" }}
          onClick={() => setShowDropDownMenu(!showDropDownMenu)}
        />
        <DropDownMenu
          showDropDownMenu={showDropDownMenu}
          setShowDropDownMenu={setShowDropDownMenu}
          setShowTopNavBarMenu={setShowTopNavBarMenu}
        />
      </TopNavBarMenuStyleMobile>
    </TopNavBarStyleContainer>
  );
};

export default TopNavBar;
