import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { WithdrawFundCard, WithdrawFundWrapper } from "./WithdrawFund.styled";
import styled from "styled-components";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import FormError from "../../components/FormError/FormError";
import { useLocation } from "react-router-dom";
import { convertInlineStyles } from "../../utils/utils";
import { withdrawal as withdrawalAction } from "../../features/withdrawal/withdrawalActions";
import Info from "../../components/Info/Info";

const WithdrawFund = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    withdrawalIsLoading,
    // withdrawal
  } = useSelector((state) => state.withdrawal);
  const { user } = useSelector((state) => state.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount_requested: "",
      wallet_address: "",
      receive_mode: location.state.name,
    },
    validationSchema: Yup.object({
      amount_requested: Yup.number()
        .required(),
        // .min(100000, "Insufficient fund"),
      wallet_address: Yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(withdrawalAction({ owner: user.id, ...values }))
        .unwrap()
        .then(() => navigate("/dashboard/accounthistory"));
    },
  });

  return (
    <WithdrawFundWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Request for Withdrawal
      </h1>
      <WithdrawFundCard>
        <Info>
          Your Payment Method is <b>{location.state.name}</b>
        </Info>
        <PSForm onSubmit={formik.handleSubmit}>
          <PSFormGrid>
            <PSFormGroup>
              <label htmlFor="amount_requested">Enter amount to withdraw</label>
              <PSInput
                type="number"
                id="amount_requested"
                name="amount_requested"
                onChange={formik.handleChange}
                value={formik.values.amount_requested}
                placeholder="Enter amount"
              />
              {formik.errors.amount_requested ? (
                <FormError>{"Withdrawal processing"}</FormError>
              ) : null}
              {/* <FormError>{withdrawal?.amount_requested}</FormError> */}
            </PSFormGroup>
            {/* <PSFormGroup>
              <label htmlFor="code">Enter OTP</label>
              <PSInput
                type="text"
                id="code"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Enter code"
              />
              {formik.errors.code ? (
                <FormError>{formik.errors.code}</FormError>
              ) : null}
              <FormError>{authError?.code}</FormError>
            </PSFormGroup> */}
            <PSFormGroup>
              <label htmlFor="wallet_address">
                Enter {location.state.name} Address
              </label>
              <PSInput
                type="text"
                id="wallet_address"
                name="wallet_address"
                onChange={formik.handleChange}
                value={formik.values.wallet_address}
                placeholder={`Enter ${location.state.name} Address`}
              />
              {formik.errors.wallet_address ? (
                <FormError>{formik.errors.wallet_address}</FormError>
              ) : null}
              {/* <FormError>{withdrawal?.wallet_address}</FormError> */}
            </PSFormGroup>
          </PSFormGrid>
          <PSSubmitBtn type="submit" disabled={withdrawalIsLoading}>
            {withdrawalIsLoading ? <Loading /> : "Complete Request"}
          </PSSubmitBtn>
        </PSForm>
      </WithdrawFundCard>
    </WithdrawFundWrapper>
  );
};

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(1, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSInput = styled.input`
  height: 40px;
  padding-inline: 5px;
  outline: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  background-color: ${({theme}) => theme.body};
  border: 1px solid #ced4da;
  margin-block-start: 10px;
`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default WithdrawFund;
