import React from "react";
import {
  BalanceCardH5,
  BalanceCardSpan,
  BalanceCardWrapper,
  BalanceCardd,
} from "./BalanceCard.styled";

const BalanceCard = ({ data }) => {
  return (
    <BalanceCardWrapper>
      <BalanceCardd>
        <BalanceCardSpan>{data.icon}</BalanceCardSpan>
        <BalanceCardH5>
          <b>
            {data.balance} {data.name}
          </b>
        </BalanceCardH5>
      </BalanceCardd>
    </BalanceCardWrapper>
  );
};

export default BalanceCard;
