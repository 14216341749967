import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { slugify } from "../../helper";

const endPoint = process.env.REACT_APP_API_URL;

export const getUser = createAsyncThunk(
  "user/getUser",
  async ({ empty }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const authUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(
        `${endPoint}/users/${slugify(authUser.username)}/`,
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(await error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (
    { full_name, email, phone_number, date_of_birth, nationality },
    thunkApi
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const authUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.put(
        `${endPoint}/users/${slugify(authUser.username)}/`,
        { full_name, email, phone_number, date_of_birth, nationality },
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(await error.response.data);
    }
  }
);

export const getUserReferrals = createAsyncThunk(
  "user/getUserReferrals",
  async ({ ref_code }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${endPoint}/users/?referral=${ref_code}`,
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(await error.response.data);
    }
  }
);

export const getNotificationSettings = createAsyncThunk(
  "user/getNotificationSettings",
  async ({ owner_id }, thunkApi) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${endPoint}/notification-settings/${owner_id}/`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(await error.response.data);
    }
  }
);
export const updateNotificationSettings = createAsyncThunk(
  "user/updateNotificationSettings",
  async (
    {
      owner_id,
      send_email_otp_on_withdrawal,
      send_email_when_profit,
      send_email_when_plan_expire,
    },
    thunkApi
  ) => {
    try {
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.patch(
        `${endPoint}/notification-settings/${owner_id}/`,
        {
          owner: owner_id,
          send_email_otp_on_withdrawal,
          send_email_when_profit,
          send_email_when_plan_expire,
        },
        config
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(await error.response.data);
    }
  }
);
