import styled from "styled-components";

export const DropDownMenuWrapper = styled.ul`
  position: absolute;
  display: ${({ showDropDownMenu }) => (showDropDownMenu ? "block" : "none")};
  left: auto;
  right: 15px;
  top: 40px;
  background-color: ${({theme}) => theme.body};
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15);
  padding-bottom: 8px;
  padding-left: 0px;
  z-index: 1000;
  background-clip: padding-box;
  width: 260px;
  list-style: none;

  @media only screen and (min-width: 991px) {
    top: 50px;
    &::after {
      border-bottom: 8px solid ${({theme}) => theme.body};;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: "";
      right: 15px;
      top: -8px;
      position: absolute;
      z-index: 1001;
    }
  }
`;
