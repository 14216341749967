import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import Loading from "../../../components/Loading/Loading";
import { updateUser } from "../../../features/user/UserActions";
import FormError from "../../../components/FormError/FormError";

const PersonalSettings = () => {
  const dispatch = useDispatch();
  const { user, userIsLoading } = useSelector((state) => state.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: user.full_name,
      email: user.email,
      phone_number: user.phone_number,
      date_of_birth: user.date_of_birth,
      nationality: user.nationality,
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone_number: Yup.number().required(),
      date_of_birth: Yup.string().required(),
      nationality: Yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(updateUser(values))
        .unwrap()
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
    },
  });

  return (
    <PSFormWrapper>
      <PSForm onSubmit={formik.handleSubmit}>
        <PSFormGrid>
          <PSFormGroup>
            <label htmlFor="full_name">Fullname</label>
            <PSInput
              type="text"
              id="full_name"
              name="full_name"
              onChange={formik.handleChange}
              value={formik.values.full_name}
            />
            <FormError>{formik.errors?.full_name}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="email">Email</label>
            <PSInput
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled
            />
            <FormError>{formik.errors?.email}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="phone_number">Phone Number</label>
            <PSInput
              type="tel"
              id="phone_number"
              name="phone_number"
              onChange={formik.handleChange}
              value={formik.values.phone_number}
            />
            <FormError>{formik.errors?.phone_number}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="date_of_birth">Date of Birth</label>
            <PSInput
              type="date"
              id="date_of_birth"
              name="date_of_birth"
              onChange={formik.handleChange}
              value={formik.values.date_of_birth}
            />
            <FormError>{formik.errors?.date_of_birth}</FormError>
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="nationality">Nationality</label>
            <PSInput
              type="address"
              id="nationality"
              name="nationality"
              onChange={formik.handleChange}
              value={formik.values.nationality}
            />
            <FormError>{formik.errors?.nationality}</FormError>
          </PSFormGroup>
          <div></div>
        </PSFormGrid>
        <PSSubmitBtn type="submit">
          {userIsLoading ? <Loading /> : "Update Profile"}
        </PSSubmitBtn>
      </PSForm>
    </PSFormWrapper>
  );
};

const PSFormWrapper = styled.div``;

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(2, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSInput = styled.input`
  height: 40px;
  padding-inline: 5px;
  outline: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border: 1px solid #ced4da;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default PersonalSettings;
