import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TopNavBar from "../../components/TopNavBar/TopNavBar";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import Footer from "../../components/Footer/Footer";
import { MainPanel } from "./Protected.styled";
import { useDispatch } from "react-redux";
import { getUser } from "../../features/user/UserActions";

const Protected = ({ isAuthenticated, themeToggler, themeState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ShowSideNavBar, setShowSideNavBar] = useState(false);

  useEffect(() => {
    dispatch(getUser({}));
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <TopNavBar
        setShowSideNavBar={setShowSideNavBar}
        ShowSideNavBar={ShowSideNavBar}
        themeToggler={themeToggler}
        themeState={themeState}
      />
      <SideNavBar
        setShowSideNavBar={setShowSideNavBar}
        ShowSideNavBar={ShowSideNavBar}
      />
      <MainPanel ShowSideNavBar={ShowSideNavBar}>
        <Outlet />
        <Footer />
      </MainPanel>
    </>
  );
};

export default Protected;
