import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const ReferUserWrapper = styled(MainWrapper)``;
export const ReferUserCardWrapper = styled.div``;
export const ReferUserCard = styled.div`
  position: relative;
  word-wrap: break-word;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem !important;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border: 0;
`;

export const ReferUserInput = styled.input`
  padding: 0px 1rem;
  border-bottom-left-radius: 0px;
  border: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;

  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;

  outline: none;
`;

export const ReferUserButton = styled.button`
  padding: 1px 1rem;
  border-bottom-left-radius: 0px;
  border: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 41px;

  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text};
  border: 0.1px solid #ced4da;

  margin-inline-start: -1px;
  cursor: pointer;
`;
