import React from "react";
import { convertInlineStyles } from "../../utils/utils";
import {
  TableCard,
  TableElement,
  TableHeadElement,
  TableInput,
  TableResponsive,
  TableRow,
  TableRowElement,
  TableSelect,
  UserTableWrapper,
} from "./Table.styled";
import { Link } from "react-router-dom";

const Table = ({ data, tableHead }) => {
  return (
    <TableCard>
      <TableResponsive>
        <UserTableWrapper>
          <TableRow
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <div className="dataTables_length" id="UserTable_length">
                <label>
                  Show{" "}
                  <TableSelect
                    name="UserTable_length"
                    aria-controls="UserTable"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </TableSelect>{" "}
                  entries
                </label>
              </div>
            </div>
            <div>
              <div id="UserTable_filter" className="dataTables_filter">
                <label>
                  Search:
                  <TableInput
                    type="search"
                    placeholder=""
                    aria-controls="UserTable"
                  />
                </label>
              </div>
            </div>
          </TableRow>
          <TableRow style={{ display: "block", overflowX: "scroll" }}>
            <div>
              <TableElement>
                <TableHeadElement>
                  <TableRowElement role="row">
                    {tableHead.map((thData, index) => (
                      <th
                        key={index}
                        className="sorting_desc"
                        tabIndex="0"
                        aria-controls="UserTable"
                        rowSpan="1"
                        colSpan="1"
                        aria-sort="descending"
                        aria-label="Plan: activate to sort column ascending"
                        style={convertInlineStyles("width: 96.6875px;")}
                      >
                        {thData}
                      </th>
                    ))}
                  </TableRowElement>
                </TableHeadElement>
                <tbody>
                  {data.length ? (
                    data.map((data, index) => (
                      <TableRowElement key={index}>
                        {Object.values(data).map((data, index) => (
                          <td key={index}>{data}</td>
                        ))}
                      </TableRowElement>
                    ))
                  ) : (
                    <TableRowElement>
                      <td colSpan={tableHead.length}>
                        No data available in table
                      </td>
                    </TableRowElement>
                  )}
                </tbody>
              </TableElement>
            </div>
          </TableRow>
          <TableRow
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="col-sm-12 col-md-5">
              <div
                className="dataTables_info"
                id="UserTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 0 to 0 of 0 entries
              </div>
            </div>
            <div className="col-sm-12 col-md-7">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="UserTable_paginate"
              >
                <ul
                  style={convertInlineStyles(
                    "display: flex; margin: 2px 0; white-space: nowrap; justify-content: flex-end;"
                  )}
                >
                  <li
                    style={convertInlineStyles(
                      "display: flex; padding-left: 0; list-style: none;"
                    )}
                  >
                    <Link
                      href="/#"
                      aria-controls="UserTable"
                      data-dt-idx="0"
                      tabIndex="0"
                      style={convertInlineStyles(
                        "color: #6c757d; pointer-events: none; cursor: auto; background-color: #fff; border-color: #dee2e6; text-decoration: none; border-radius: 10px; border: 1px solid #dee2e6; padding: 0.5rem 0.75rem; line-height: 1.25;"
                      )}
                    >
                      Previous
                    </Link>
                  </li>
                  <li
                    style={convertInlineStyles(
                      "display: flex; padding-left: 5px; list-style: none;"
                    )}
                  >
                    <Link
                      href="/#"
                      aria-controls="UserTable"
                      data-dt-idx="1"
                      tabIndex="0"
                      style={convertInlineStyles(
                        "color: #6c757d; pointer-events: none; cursor: auto; background-color: #fff; border-color: #dee2e6; text-decoration: none; border-radius: 10px; border: 1px solid #dee2e6; padding: 0.5rem 0.75rem; line-height: 1.25;"
                      )}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </TableRow>
        </UserTableWrapper>
      </TableResponsive>
    </TableCard>
  );
};

export default Table;
