import styled from "styled-components";

export const PricingTableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
`;
export const PricingTableItem = styled.div`
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  transition: .3s;
  padding: 1.5rem!important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  border: 1px solid #dee2e6;
`;
