import React, { useEffect } from "react";
import {
  PPaymentSubmitBtn,
  PaymentCard,
  PaymentWrapper,
} from "./Payment.styled";
import { convertInlineStyles } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copyToClipboard from "../../utils/copyToClipboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { makeDeposit } from "../../features/deposit/depositActions";
import Loading from "../../components/Loading/Loading";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const { user } = useSelector((state) => state.user);
  const { makeDepositIsLoading } = useSelector((state) => state.deposit);

  let address;
  let network_type;

  switch (state.payment_method) {
    case "Binance Coin (BNB)":
      address = "0xfe264f181903b2c5fb9c1f72d517fa2054a43316";
      network_type = "Memo/bep2: 108806651";
      break;
    case "Ripple (XRP)":
      address = "0xfe264f181903b2c5fb9c1f72d517fa2054a43316";
      network_type = "Memo: 1912294927";
      break;
    case "Tron (TRX)":
      address = "TQyEFML3Y95XxstJapoHp9sPwvH2swnQUN";
      network_type = "Trc20";
      break;
    case "Tether (USDT)":
      address = "TQyEFML3Y95XxstJapoHp9sPwvH2swnQUN";
      network_type = "Trc20";
      break;
    case "Ethereum (ETH)":
      address = "0xfe264f181903b2c5fb9c1f72d517fa2054a43316";
      network_type = "Erc20";
      break;
    case "Bitcoin (BTC)":
      address = "1MzNyKBsawcWJPYiJ4j9bFyxQeyuG1Dcuz";
      network_type = "bitcoin";
      break;

    default:
      break;
  }

  useEffect(() => {
    !state && navigate("/dashboard/deposit");
  }, [navigate, state]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: state.amount,
      pay_mode: state.payment_method,
      payment_proof: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      dispatch(makeDeposit({ owner: user.id, ...values }))
        .unwrap()
        .then((res) => navigate("/dashboard/accounthistory"));
    },
  });

  return (
    <PaymentWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Make Payment
      </h1>
      <PaymentCard>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ marginBlock: "10px" }}>
            You are to make payment of ${state.amount} using your selected
            payment method. Screenshot and upload the proof of payment
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${address}&choe=UTF-8`}
              alt="qr"
              height={200}
              width={200}
            />
            <h3 style={{ marginInlineStart: "20px" }}>
              {state.payment_method}
            </h3>
          </div>
          <h2>{state.payment_method} Address:</h2>
          <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
            <input
              type="text"
              value={address}
              id="myInput"
              readonly=""
              style={convertInlineStyles(
                `
                  background: #e8e8e8;
                  border-color: #e8e8e8;
                  padding: 0px 1rem;
                  border-bottom-left-radius: 0px;
                  border: 0;
                  width: 100%;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  height: 40px !important;
                  `
              )}
            />
            <div>
              <button
                type="button"
                style={convertInlineStyles(
                  `
                    padding: 0.6rem 1rem;
                    marging-left: -1px;
                    height: 40px !important;
                    border: 1px solid #000;
                    cursor: pointer;
                    `
                )}
                onClick={() => copyToClipboard(address)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </div>
          </div>
          <div>Network Type: {network_type}</div>
          <div>
            <div style={{ marginBlock: "20px" }}>
              Upload Payment proof after payment.
            </div>
            <input
              type="file"
              accept="image/*"
              name="payment_proof"
              // onChange={formik.handleChange}
              // value={formik.values.payment_proof}
              onChange={(event) => {
                const file = event.currentTarget.files[0];
                formik.setFieldValue("payment_proof", file);
              }}
            />
          </div>
          <PPaymentSubmitBtn type="submit" disabled={makeDepositIsLoading}>
            {makeDepositIsLoading ? <Loading /> : "Submit Payment"}
          </PPaymentSubmitBtn>
        </form>
      </PaymentCard>
    </PaymentWrapper>
  );
};

export default Payment;
