const copyToClipboard = async (text, message="coppied successfully") => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        alert(message);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert(message + " (fallback)");
      }
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };
  
  export default copyToClipboard;