import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Protected from "./containers/Protected/Protected";
import Auth from "./containers/Auth/Auth";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import TradingHistory from "./pages/TradingHistory/TradingHistory";
import Index from "./pages";
import AccountHistory from "./pages/AccountHistory/AccountHistory";
import CryptoExchange from "./pages/CryptoExchange/CryptoExchange";
import Trading from "./pages/Trading/Trading";
import StakingPlan from "./pages/StakingPlan/StakingPlan";
import ReferUser from "./pages/ReferUser/ReferUser";
import Support from "./pages/Support/Support";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Withdrawal from "./pages/Withdrawal/Withdrawal";
import WithdrawFund from "./pages/WithdrawFund/WithdrawFund";
import Deposit from "./pages/Deposit/Deposit";
import Payment from "./pages/Payment/Payment";
import {
  useEffect,
  useState,
  // useState
} from "react";
import { logoutUser } from "./features/auth/AuthActions";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./theme/GlobalStyles";
import { lightTheme, darkTheme } from "./theme/Theme";
import TradingPlans from "./pages/TradingPlans/TradingPlans";

const isAuthenticated = localStorage.getItem("token");

function App() {
  const dispatch = useDispatch();
  const savedTheme = localStorage.getItem("theme");
  const [themeState, setThemeState] = useState("");

  const themeToggler = () => {
    if (savedTheme === "dark") {
      setThemeState("light");
      localStorage.setItem("theme", "light");
    } else {
      setThemeState("dark");
      localStorage.setItem("theme", "dark");
    }
  };

  useEffect(() => {
    setThemeState(savedTheme);
  }, [savedTheme]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        dispatch(logoutUser({}));
        window.location.pathname = "/login";
      }
    }
  }, [dispatch]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Protected
          isAuthenticated={isAuthenticated}
          themeToggler={themeToggler}
          themeState={themeState}
        />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Index />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/dashboard/tradinghistory",
          element: <TradingHistory />,
        },
        {
          path: "/dashboard/accounthistory",
          element: <AccountHistory />,
        },
        {
          path: "/dashboard/asset-balance",
          element: <CryptoExchange />,
        },
        {
          path: "/dashboard/trading",
          element: <Trading />,
        },
        {
          path: "/dashboard/trading-plans",
          element: <TradingPlans />,
        },
        {
          path: "/dashboard/buy-plan",
          element: <StakingPlan />,
        },
        {
          path: "/dashboard/myplans",
          element: <StakingPlan />,
        },
        {
          path: "/dashboard/referuser",
          element: <ReferUser />,
        },
        {
          path: "/dashboard/support",
          element: <Support />,
        },
        {
          path: "/dashboard/account-settings",
          element: <AccountSettings />,
        },
        {
          path: "/dashboard/deposits",
          element: <Deposit />,
        },
        {
          path: "/dashboard/payment",
          element: <Payment />,
        },
        {
          path: "/dashboard/withdrawals",
          element: <Withdrawal />,
        },
        {
          path: "/dashboard/withdraw-funds",
          element: <WithdrawFund />,
        },
      ],
    },
    {
      path: "/",
      element: <Auth isAuthenticated={isAuthenticated} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/login",
          element: <Login />,
          index: true,
        },
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={savedTheme === "dark" ? darkTheme : lightTheme}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
export default App;
