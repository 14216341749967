import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import Loading from "../../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../features/user/UserActions";

const OtherSettingsToggle = ({ answer, setAnswer }) => {
  return (
    <div style={{ display: "flex", marginBlock: "10px" }}>
      <div
        style={{
          padding: "10px 20px",
          border: `${answer ? ".5px solid #1572e8" : ".5px solid #000"}`,
          borderInlineEnd: `${answer ? ".5px solid #1572e8" : "none"}`,
          color: `${answer ? "#1572e8" : "black"}`,
          background: `${answer ? "rgba(21, 114, 232, 0.15)" : ""}`,
          cursor: "pointer",
        }}
        onClick={() => setAnswer(true)}
      >
        Yes
      </div>
      <div
        style={{
          padding: "10px 20px",
          border: `${!answer ? ".5px solid #1572e8" : ".5px solid #000"}`,
          borderInlineStart: `${!answer ? ".5px solid #1572e8" : "none"}`,
          color: `${!answer ? "#1572e8" : "black"}`,
          background: `${!answer ? "rgba(21, 114, 232, 0.15)" : ""}`,
          cursor: "pointer",
        }}
        onClick={() => setAnswer(false)}
      >
        No
      </div>
    </div>
  );
};

const OtherSettings = () => {
  const dispatch = useDispatch();
  const { user, userIsLoading } = useSelector((state) => state.user);
  const [answer, setAnswer] = useState({
    send_email_otp_on_withdrawal: null,
    send_email_when_profit: null,
    send_email_when_plan_expire: null,
  });

  useEffect(() => {
    dispatch(getNotificationSettings({ owner_id: user.id })).then((res) => {
      setAnswer({
        send_email_otp_on_withdrawal: res.payload.send_email_otp_on_withdrawal,
        send_email_when_profit: res.payload.send_email_when_profit,
        send_email_when_plan_expire: res.payload.send_email_when_plan_expire,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      send_email_otp_on_withdrawal: answer.send_email_otp_on_withdrawal,
      send_email_when_profit: answer.send_email_when_profit,
      send_email_when_plan_expire: answer.send_email_when_plan_expire,
    },
    validationSchema: Yup.object({
      send_email_otp_on_withdrawal: Yup.boolean(),
      send_email_when_profit: Yup.boolean(),
      send_email_when_plan_expire: Yup.boolean(),
    }),
    onSubmit: (values) => {
      dispatch(updateNotificationSettings({ owner_id: user.id, ...values }));
    },
  });

  return (
    <PSFormWrapper>
      <PSForm onSubmit={formik.handleSubmit}>
        <PSFormGrid>
          <PSFormGroup>
            <label htmlFor="old_password">
              Send confirmation OTP to my email when withdrawing my funds.
            </label>
            <OtherSettingsToggle
              answer={answer.send_email_otp_on_withdrawal}
              setAnswer={(newAnswer) =>
                setAnswer({
                  ...answer,
                  send_email_otp_on_withdrawal: newAnswer,
                })
              }
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="new_password1">
              Send me email when i get profit.
            </label>
            <OtherSettingsToggle
              answer={answer.send_email_when_profit}
              setAnswer={(newAnswer) =>
                setAnswer({ ...answer, send_email_when_profit: newAnswer })
              }
            />
          </PSFormGroup>
          <PSFormGroup>
            <label htmlFor="new_password2">
              Send me email when my investment plan expires.
            </label>
            <OtherSettingsToggle
              answer={answer.send_email_when_plan_expire}
              setAnswer={(newAnswer) =>
                setAnswer({ ...answer, send_email_when_plan_expire: newAnswer })
              }
            />
          </PSFormGroup>
        </PSFormGrid>
        <PSSubmitBtn type="submit">
          {userIsLoading ? <Loading /> : "Save"}
        </PSSubmitBtn>
      </PSForm>
    </PSFormWrapper>
  );
};

const PSFormWrapper = styled.div``;

const PSForm = styled.form``;

const PSFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 20px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(2, 2fr);
  }
`;

const PSFormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;

export default OtherSettings;
