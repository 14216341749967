import styled from "styled-components";
import { MainWrapper } from "../../containers/Protected/Protected.styled";

export const PaymentWrapper = styled(MainWrapper)``;

export const PaymentCard = styled.div`
  border-radius: 5px;
  border-bottom-left-radius: 0;
  background-color: ${({ theme }) => theme.body};
  margin-bottom: 30px;
  margin-inline: auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;
  padding: 1rem;

  @media only screen and (min-width: 991px) {
    width: 70%;
  }
`;

export const PPaymentSubmitBtn = styled.button`
  padding: 15px 50px;
  margin-block: 20px;
  background-color: #1472e8;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
`;
