import React, { useEffect } from "react";
import {
  Card,
  CardH5,
  CardSmall,
  CardSpan,
  CardWrapper,
  DashboardWraper,
  UserH2,
  UserH5,
} from "./Dashboard.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faCoins,
  faDollarSign,
  faDownload,
  faEnvelope,
  faEnvelopeOpen,
  faGift,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getWallet } from "../../features/wallet/walletActions";
import { getDeposits } from "../../features/deposit/depositActions";
import { getWithdrawals } from "../../features/withdrawal/withdrawalActions";
import { getProfits } from "../../features/profit/ProfitlActions";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { wallet } = useSelector((state) => state.wallet);
  const { deposits } = useSelector((state) => state.deposit);
  const { withdrawals } = useSelector((state) => state.withdrawal);
  const { profits, profitsIsLoading } = useSelector((state) => state.profit);

  useEffect(() => {
    dispatch(getWallet({ owner_id: user.id }));
    dispatch(getDeposits({ owner_id: user.id }));
    dispatch(getWithdrawals({ owner_id: user.id }));
    dispatch(getProfits({ owner_id: user.id }));
  }, [dispatch, user]);

  const totalDeposit = deposits
    .filter((deposit) => deposit.status === "completed")
    .reduce((accumulator, object) => {
      return parseFloat(accumulator) + parseFloat(object.amount);
    }, 0);

  const totalWithdrawal = withdrawals
    .filter((deposit) => deposit.status === "completed")
    .reduce((accumulator, object) => {
      return parseFloat(accumulator) + parseFloat(object.amount_requested);
    }, 0);

  const totalProfit = profits.reduce((accumulator, object) => {
    return parseFloat(accumulator) + parseFloat(object.amount);
  }, 0);

  const accountBalance =
    parseFloat(wallet?.bonus) + parseFloat(wallet?.balance);

  const cardData = [
    {
      name: "Account Balance",
      balance: `$${accountBalance.toFixed(2)}`,
      iconBGColor: "#6861ce",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faDollarSign} />,
    },
    {
      name: "Total Profit",
      balance: `$${!profitsIsLoading ? totalProfit.toFixed(2) : ""}`,
      iconBGColor: "#31ce36",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faCoins} />,
    },
    {
      name: "Total Bonus",
      balance: `$${wallet?.bonus}`,
      iconBGColor: "#6861ce",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faGift} />,
    },
    {
      name: "Total Referral Bonus",
      balance: `$${wallet?.referral_bonus}`,
      iconBGColor: "#48abf7",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faRetweet} />,
    },
    {
      name: "Total Stakes",
      balance: user?.total_stakes,
      iconBGColor: "#f25961",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
    {
      name: "Total Active Stakes",
      balance: user?.total_active_stakes,
      iconBGColor: "#31ce36",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faEnvelopeOpen} />,
    },
    {
      name: "Total Deposit",
      balance: `$${totalDeposit.toFixed(2)}`,
      iconBGColor: "#ffad46",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faDownload} />,
    },
    {
      name: "Total Withdrawals",
      balance: `$${totalWithdrawal.toFixed(2)}`,
      iconBGColor: "#f25961",
      iconColor: "#fff",
      icon: <FontAwesomeIcon icon={faArrowAltCircleUp} />,
    },
  ];

  return (
    <DashboardWraper>
      <div>
        <UserH2>Welcome, {user?.full_name}!</UserH2>
        <UserH5>Welcome to StakecornServices</UserH5>
      </div>
      <CardWrapper>
        {cardData.map((data, index) => (
          <Card key={index}>
            <CardSpan iconBGColor={data.iconBGColor} iconColor={data.iconColor}>
              {data.icon}
            </CardSpan>
            <div>
              <CardH5>
                <b>{data.balance}</b>
              </CardH5>
              <CardSmall>{data.name}</CardSmall>
            </div>
          </Card>
        ))}
      </CardWrapper>
      <div>
        <h3 style={{ color: "rgba(169, 175, 187, 0.82)" }}>
          Personal Trading Chart
        </h3>
        <div
          className="tradingview-widget-container"
          style={{ margin: "30px 0px 10px 0px" }}
        >
          <div id="tradingview_f933e">
            <div
              id="tradingview_56192-wrapper"
              style={{
                position: "relative",
                boxSizing: "content-box",
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Trebuchet MS", Roboto, Ubuntu, sans-serif',
                margin: "0px auto !important",
                padding: "0px !important",
                width: "100%",
                height: "calc(518px)",
                backgroundColor: "#000",
              }}
            >
              <iframe
                title="advanced chart TradingView widget"
                lang="en"
                id="tradingview_56192"
                frameborder="0"
                allowtransparency="true"
                allowfullscreen="true"
                src="https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22COINBASE%3ABTCUSD%22%2C%22frameElementId%22%3A%22tradingview_56192%22%2C%22interval%22%3A%221%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22BB%40tv-basicstudies%22%2C%22theme%22%3A%22light%22%2C%22style%22%3A%229%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22stakecornservices.finance%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22COINBASE%3ABTCUSD%22%2C%22page-uri%22%3A%22stakecornservices.finance%2Fdashboard%22%7D"
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "0px !important",
                  padding: "0px !important",
                }}
              ></iframe>
            </div>
          </div>
          <div
            className="tradingview-widget-copyright"
            style={{ width: "100%", textAlign: "center" }}
          >
            <a
              href="/"
              rel="noopener"
              target="_blank"
              style={{
                color: "#007bff",
                textDecoration: "none",
              }}
            >
              <span></span> <span>Personal trading chart</span>
            </a>
          </div>
        </div>
      </div>
      <div
        className="white-box"
        style={{ height: "450px", width: "100%", marginBlockEnd: "32px" }}
      >
        <h4
          style={{
            marginBottom: "5px",
            color: "rgba(169, 175, 187, 0.82)",
          }}
        >
          {" "}
          Forex Market Fundamental Data
        </h4>
        <div style={{ width: "100%", height: "100%" }}>
          <iframe
            allowtransparency="true"
            frameborder="0"
            src="https://www.tradingview-widget.com/embed-widget/forex-cross-rates/?locale=en#%7B%22currencies%22%3A%5B%22EUR%22%2C%22USD%22%2C%22JPY%22%2C%22BTC%22%2C%22ETH%22%2C%22LTC%22%2C%22GBP%22%2C%22CHF%22%2C%22AUD%22%2C%22CAD%22%2C%22NZD%22%2C%22CNY%22%5D%2C%22isTransparent%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22utm_source%22%3A%22stakecornservices.finance%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forex-cross-rates%22%2C%22page-uri%22%3A%22stakecornservices.finance%2Fdashboard%22%7D"
            title="forex cross-rates TradingView widget"
            lang="en"
            style={{
              userSelect: "none",
              boxSizing: "border-box",
              display: "block",
              // height: "calc(100% - 32px)",
              // width: "100%;",
              width: "100%",
              height: "100%",
              margin: "0px !important",
              padding: "0px !important",
            }}
          ></iframe>
          <div
            style={{
              height: "32px",
              lineHeight: "32px",
              width: "100%",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <a
              rel="noreferrer"
              target="_blank"
              href="http://www.tradingview.com/?utm_source=stakecornservices.finance&amp;utm_medium=widget&amp;utm_campaign=forex-cross-rates"
              style={{
                color: "rgb(173, 174, 176)",
                fontFamily: `"Trebuchet MS", Tahoma, Arial, sans-serif; font-size: 13px`,
              }}
            >
              .
            </a>
          </div>
        </div>
      </div>
    </DashboardWraper>
  );
};

export default Dashboard;
