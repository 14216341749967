import React from "react";
import {
  WithdrawalCoin,
  WithdrawalCoinBtn,
  WithdrawalCoinRow,
  WithdrawalCoinWrapper,
  WithdrawalWrapper,
} from "./Withdrawal.styled";
import { convertInlineStyles } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const Withdrawal = () => {
  const navigate = useNavigate();
  return (
    <WithdrawalWrapper>
      <h1
        style={convertInlineStyles(
          "color: #343a40; line-height: 1.4; font-size: 1.725rem; font-weight: 500;"
        )}
      >
        Request for Withdrawal
      </h1>
      <WithdrawalCoinWrapper>
        {[
          {
            name: "Binance Coin (BNB)",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "",
          },
          {
            name: "Ripple (XRP)",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "",
          },
          {
            name: "Tron (TRX)",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "",
          },
          {
            name: "Tether (USDT)",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "",
          },
          {
            name: "Ethereum",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "Instant",
          },
          {
            name: "Bitcoin",
            min_amount: 100,
            max_amount: 1000000000000,
            charge_type: "Fixed",
            charge_amount: 0,
            duration: "Instant",
          },
        ].map((data, index) => (
          <WithdrawalCoin key={index}>
            <h2>{data.name}</h2>
            {/* <WithdrawalCoinRow>
              <div>Minimum amount:</div>
              <b style={{ fontWeight: "bolder" }}>
                {data.min_amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </WithdrawalCoinRow> */}
            <WithdrawalCoinRow>
              <div>Max amount:</div>
              <b style={{ fontWeight: "bolder" }}>
                {data.max_amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </WithdrawalCoinRow>
            <WithdrawalCoinRow>
              <div>Charge Type:</div>
              <b style={{ fontWeight: "bolder" }}>{data.charge_type}</b>
            </WithdrawalCoinRow>
            <WithdrawalCoinRow>
              <div>Charges Amount:</div>
              <b style={{ fontWeight: "bolder" }}>
                {data.charge_amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </WithdrawalCoinRow>
            {data.duration && <WithdrawalCoinRow>
              <div>Duration:</div>
              <b style={{ fontWeight: "bolder" }}>{data.duration}</b>
            </WithdrawalCoinRow>}
            <WithdrawalCoinBtn
              onClick={() =>
                navigate("/dashboard/withdraw-funds", { state: { ...data } })
              }
            >
              Request withdrawal
            </WithdrawalCoinBtn>
          </WithdrawalCoin>
        ))}
      </WithdrawalCoinWrapper>
    </WithdrawalWrapper>
  );
};

export default Withdrawal;
