import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/AuthSlice";
import userReducer from "../features/user/UserSlice";
import withdrawalReducer from "../features/withdrawal/withdrawalSlice";
import depositReducer from "../features/deposit/depositSlice";
import walletReducer from "../features/wallet/walletSlice";
import historyReducer from "../features/history/historySlice";
import profitReducer from "../features/profit/ProfitSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    deposit: depositReducer,
    user: userReducer,
    withdrawal: withdrawalReducer,
    wallet: walletReducer,
    history: historyReducer,
    profit: profitReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
