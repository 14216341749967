export const convertInlineStyles = (htmlStyleString) => {
  const stylesArray = htmlStyleString.split(";").filter(Boolean);

  const reactStyles = stylesArray.reduce((acc, style) => {
    const [property, value] = style.split(":").map((s) => s.trim());
    acc[
      property.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
    ] = value;
    return acc;
  }, {});

  return reactStyles;
};
