import { createSlice } from "@reduxjs/toolkit";
import { getWallet } from "./walletActions";

const initialState = {
  wallet: {},
  walletError: null,
  walletIsLoading: false,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: {
    [getWallet.fulfilled]: (state, {payload}) => {
      state.walletIsLoading = false;
      state.wallet = payload;
    },
    [getWallet.rejected]: (state, payload) => {
      state.walletIsLoading = false;
      state.walletError = payload;
    },
    [getWallet.pending]: (state) => {
      state.walletIsLoading = true;
    },
  },
});

export default walletSlice.reducer;
